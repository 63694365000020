const PORT = "8888";
const URL_BASE = `http://localhost:${PORT}`;
const URL_MICRO_SERVICE: any = {
   CONTACT_COMMANDE_SERVICE: `${URL_BASE}/CONTACT-COMMANDE-SERVICE/api/commands/`,
   CONTACT_QUERY_SERVICE: `${URL_BASE}/CONTACT-QUERY-SERVICE/api/query/`,
   DON_COMMANDE_SERVICE: `${URL_BASE}/DON-COMMANDE-SERVICE/api/commands/`,
   DON_QUERY_SERVICE: `${URL_BASE}/DON-QUERY-SERVICE/api/query/`,
   PROJET_COMMANDE_SERVICE: `${URL_BASE}/PROJET-COMMANDE-SERVICE/api/commands/`,
   PROJET_QUERY_SERVICE: `${URL_BASE}/PROJET-QUERY-SERVICE/api/query/`,
   UTILISATEUR_COMMANDE_SERVICE: `${URL_BASE}/UTILISATEUR-COMMANDE-SERVICE/api/commands/`,
   UTILISATEUR_QUERY_SERVICE: `${URL_BASE}/UTILISATEUR-QUERY-SERVICE/api/query/`,
};

export default URL_MICRO_SERVICE;
