import {Heading, Pane, Text} from "evergreen-ui";
import type React from "react";

interface ResumeBoxProps {
   amount?: string;
   labels?: string;
   color?: string;
   year?: string;
}

export const TotalDonBox: React.FC<ResumeBoxProps> = ({
   amount,
   labels,
   color,
   year,
}) => {
   return (
      <Pane
         elevation={4}
         float="left"
         backgroundColor={color}
         width={280}
         height={120}
         margin={10}
         border={true}
         display="flex"
         borderRadius={10}
         paddingLeft={10}
         flexDirection="column">
         <Text
            textAlign={"right"}
            marginTop={5}
            marginRight={20}
            marginBottom={25}>
            {year}
         </Text>
         <Heading textAlign={"left"} size={600}>
            <b>{labels}</b>
         </Heading>
         <Heading textAlign={"left"} size={800}>
            {amount}
         </Heading>
      </Pane>
   );
};
