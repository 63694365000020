import {translate} from "../../../i18n/translate";

export function DonationsPresentation() {
   return (
      <section className="container px-2 py-8 mx-auto">
         <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-gray-800 text-4xl font-bold">
               {translate("TR_213")}
            </h2>
            <p className="mt-7 text-lg text-gray-700 font-thin">
               {translate("TR_214")}
            </p>
         </div>
      </section>
   );
}
