import {translate} from "../i18n/translate";

function ErrorComponent({errorMessage}: any) {
   return (
      <div
         className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
         role="alert">
         <strong className="font-bold">{translate("TR_179")} </strong>
         <span className="block sm:inline">{errorMessage}</span>
      </div>
   );
}

export default ErrorComponent;
