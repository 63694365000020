import type React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
   DeleteIcon,
   EditIcon,
   EyeOnIcon,
   IconButton,
   Pagination,
   Pane,
   Table,
   Text,
   TickCircleIcon,
   TrashIcon,
} from "evergreen-ui";
import ContainerOTDE from "../container/ContainerOTDE";
import {v4 as uuidv4} from "uuid";

interface HeaderTableType {
   key: string;
   label: string;
   isFilter: boolean;
}

interface ConfigAction {
   edit?: {state: boolean; url?: string};
   delete?: {state: boolean; url?: string};
   show?: {state: boolean; url?: string};
}

interface DatatableODTEProps {
   tableHeaderlabel: HeaderTableType[];
   tableData: any[];
   loading: boolean;
   totalRow: number;
   filterValue: string;
   handleCurrenyPage: (state: any) => void;
   configAction: ConfigAction;
   handleDelete: (item: any) => void;
}

const DatatableODTE: React.FC<DatatableODTEProps> = ({
   tableHeaderlabel = [],
   tableData = [],
   loading,
   totalRow,
   filterValue,
   handleCurrenyPage,
   configAction,
   handleDelete,
}) => {
   const [data, setData] = useState(tableData);
   const [currentPage, setCurrentPage] = useState(0);
   const navigate = useNavigate();

   useEffect(() => {
      setData(tableData);
   }, [tableData]);

   useEffect(() => {
      const instanceData = filterValueAction(filterValue);
      setData(instanceData);
   }, [filterValue]);

   const filterValueAction = (value: string) => {
      const keyFilter = tableHeaderlabel
         .filter(header => header.isFilter)
         .map(head => head.key);
      const resultatsFiltres = tableData.filter(objet => {
         return keyFilter.some(propriete => {
            const valeurPropriete = objet[propriete].toString().toLowerCase();
            return valeurPropriete.includes(value.toLowerCase());
         });
      });
      return resultatsFiltres;
   };

   const getPazeSize = () => {
      return Math.round(totalRow / 10);
   };

   const deleteAction = (tempItem: any) => {
      handleDelete(tempItem);
   };

   function getActionRwo(item: any) {
      return (
         <>
            {configAction.edit?.state === true && (
               <IconButton
                  size="small"
                  appearance="default"
                  intent="none"
                  onClick={() => {
                     navigate(
                        `${configAction.edit?.url ?? ""}${
                           (item?.id as string) ?? ""
                        }`,
                     );
                  }}
                  icon={EditIcon}
               />
            )}
            {configAction.delete?.state === true && (
               <IconButton
                  size="small"
                  appearance="default"
                  intent="danger"
                  onClick={() => {
                     deleteAction(item);
                  }}
                  icon={TrashIcon}
                  className={`ml-2`}
               />
            )}
            {configAction.show?.state === true && (
               <IconButton
                  size="small"
                  appearance="default"
                  intent="primary"
                  onClick={() => {
                     navigate(
                        `${configAction.show?.url ?? ""}${
                           (item?.id as string) ?? ""
                        }`,
                     );
                  }}
                  icon={EyeOnIcon}
                  className={`ml-2`}
               />
            )}
         </>
      );
   }

   const getEmptyPreview = () => {
      if (totalRow === 0) {
         return (
            <Table.Row>
               <Table.Cell columnGap={tableHeaderlabel.length}>
                  <Text flex={1} textAlign={"center"}>
                     {"Nous n'avons pas trouvé de donnée"}
                  </Text>
               </Table.Cell>
            </Table.Row>
         );
      }
   };

   function getStatusRow(item: any, headerKey: any) {
      return (
         <div className={"text-center flex"}>
            {item[headerKey.key] === true ? (
               <TickCircleIcon flex={1} color={"#1dbe00"} size={40} />
            ) : (
               <DeleteIcon flex={1} color={"#f89797"} size={40} />
            )}
         </div>
      );
   }

   function NexPageClick() {
      if (currentPage + 1 <= getPazeSize()) {
         setCurrentPage(currentPage + 1);
         handleCurrenyPage(currentPage + 1);
      }
   }

   function PreviousPageClick() {
      console.log("currentPage-", currentPage);
      if (getPazeSize() - currentPage >= 0) {
         setCurrentPage(currentPage - 1);
         handleCurrenyPage(currentPage - 1);
      }
   }

   return (
      <ContainerOTDE loading={loading} line={10}>
         <Pane display={"flex"} flex={1} flexDirection={"column"}>
            <Table className={`w-full`} flex={1}>
               <Table.Head className={`bg-design-gray text-sm`}>
                  <Table.TextHeaderCell
                     flexBasis={60}
                     flexGrow={0}
                     flexShrink={0}
                     className="text-center">
                     #
                  </Table.TextHeaderCell>
                  {tableHeaderlabel.map((headerLabel, indx) => {
                     return (
                        <Table.TextHeaderCell
                           key={indx}
                           className="text-center">
                           {headerLabel.label}
                        </Table.TextHeaderCell>
                     );
                  })}
               </Table.Head>
               <Table.Body>
                  {data.map((item, index) => {
                     return (
                        <Table.Row
                           flex={1}
                           className={`text-sm`}
                           key={uuidv4()}>
                           {tableHeaderlabel.map((headerKey, idx) => {
                              if (headerKey.key === "action") {
                                 return (
                                    <Table.TextCell
                                       className={`text-right gap-3 text-center`}
                                       key={uuidv4()}>
                                       {getActionRwo(item)}
                                    </Table.TextCell>
                                 );
                              } else if (headerKey.key === "isActive") {
                                 return (
                                    <Table.TextCell
                                       key={uuidv4()}
                                       className="text-center">
                                       {getStatusRow(item, headerKey)}
                                    </Table.TextCell>
                                 );
                              }
                              return (
                                 // eslint-disable-next-line react/jsx-key
                                 <Table.TextCell
                                    className="text-center flex"
                                    key={uuidv4()}>
                                    {item[headerKey.key]}
                                 </Table.TextCell>
                              );
                           })}
                        </Table.Row>
                     );
                  })}
                  {getEmptyPreview()}
               </Table.Body>
            </Table>
            {getPazeSize() > 1 && (
               <Pane
                  display={"flex"}
                  flex={1}
                  marginTop={8}
                  justifyContent={"flex-end"}>
                  <Pagination
                     page={currentPage + 1}
                     totalPages={getPazeSize()}
                     onNextPage={() => {
                        NexPageClick();
                     }}
                     onPreviousPage={() => {
                        PreviousPageClick();
                     }}
                     onPageChange={e => {
                        setCurrentPage(e - 1);
                     }}></Pagination>
               </Pane>
            )}
         </Pane>
      </ContainerOTDE>
   );
};

export default DatatableODTE;
