import {CornerDialog, Pane, toaster} from "evergreen-ui";
import type {HeaderTableType} from "./../../../types/header-table.type";
import {useEffect, useState} from "react";
import DatatableODTE from "./../../component/datatable";
import Headerpanel from "../../component/headerpanel";
import {useNavigate} from "react-router-dom";
import {type DatatableProjetType} from "../../../types/datatable-projet.type";
import {type ProjetType} from "../../../types/projet.type";
import {ProjetService} from "../../../services/projet.service";

export const Projets = () => {
   const HeaderLabel: HeaderTableType[] = [
      {key: "titre", label: "Titre", isFilter: true},
      {key: "objectif", label: "Objectif", isFilter: true},
      {key: "action", label: "Action", isFilter: false},
   ];

   const title = "Liste des projets";
   const [searchValue, setSearchValue] = useState("");

   const [dateValue, setDateValue] = useState("");

   const [listeProjets, setListeProjets] = useState<DatatableProjetType>({
      projetListe: [],
      totalRow: 0,
      currentPage: 0,
   });

   const [loading, setLoading] = useState<boolean>(true);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [tempProjet, setTemprojet] = useState<ProjetType>({
      id: "0",
      titre: "",
      cover: "",
      description: "",
      objectif: "",
   });
   const configAction: any = {
      edit: {
         state: true,
         url: `manage/`,
      },
      delete: {state: true, url: ""},
   };
   const [deleteModal, setDeleteModal] = useState<boolean>(false);
   const navigate = useNavigate();
   useEffect(() => {
      getListeProjets();
      setLoading(false);
   }, [listeProjets.currentPage]);

   const getListeProjets = () => {
      setLoading(true);
      ProjetService.getListeProjets({
         pageNo: listeProjets.currentPage,
         pageSize: 10,
         sortBy: "",
         sortDir: searchValue,
      })
         .then(data => {
            setListeProjets(prevState => ({
               ...prevState,
               projetListe: data.projetListe,
               currentPage: data.currentPage,
               totalRow: data.totalRow,
            }));
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };

   function handleDeleteProjet() {
      setDeleteModal(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const projetId = tempProjet.id.toString();
      if (tempProjet.id !== "0") {
         void ProjetService.deleteProjet(projetId)
            .then(res => {
               if (res.success) {
                  toaster.success("OTDE NOTIFICATION", {
                     description: "Félicitation, le projet a été supprimé",
                  });
                  // Rafraîchir la liste des projets après la suppression
                  getListeProjets();
               }
            })
            .catch(() => {
               // Gérer les erreurs de suppression ici
               toaster.danger("OTDE NOTIFICATION", {
                  description:
                     "Une erreur s'est produite lors de la suppression du projet.",
               });
            })
            .finally(() => {
               setDeleteModal(false);
            });
      }
   }

   const handleBtnSearch = () => {};

   const handleBtnExport = () => {};

   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Headerpanel
                  title={title}
                  searchValue={searchValue}
                  dateValue={dateValue}
                  handleButtonExport={handleBtnExport}
                  handleButtonSearch={handleBtnSearch}
                  handleDateChanged={setDateValue}
                  showSeachValue={true}
                  showdatechoosed={true}
                  showexportPdf={true}
                  showSearchBtn={true}
                  showAddEntity={true}
                  handleAdd={() => {
                     navigate("manage");
                  }}
                  setSearchValue={setSearchValue}
               />
               <Pane
                  display="flex"
                  flexDirection={"row"}
                  padding={16}
                  borderRadius={3}>
                  <DatatableODTE
                     filterValue={searchValue}
                     tableHeaderlabel={HeaderLabel}
                     tableData={listeProjets.projetListe}
                     totalRow={listeProjets.totalRow}
                     loading={loading}
                     handleCurrenyPage={setTemprojet}
                     configAction={configAction}
                     handleDelete={utilisateur => {
                        setTemprojet(utilisateur);
                        setDeleteModal(true);
                     }}
                  />
                  <CornerDialog
                     title="Gestion de l'application"
                     isShown={deleteModal}
                     confirmLabel={"Supprimer"}
                     cancelLabel={"Annuler "}
                     onConfirm={handleDeleteProjet}
                     onCloseComplete={() => {
                        setDeleteModal(false);
                     }}>
                     Voulez-vous supprimer cette ligne ?
                  </CornerDialog>
               </Pane>
            </dl>
         </div>
      </div>
   );
};
