import type React from "react";
import {type ChangeEvent, useState} from "react";
import {Button, Heading, Icon, Pane, SearchIcon} from "evergreen-ui";
import {Add, PictureAsPdf, SearchOutlined} from "@mui/icons-material";

interface Props {
   searchValue: string;
   setSearchValue: React.Dispatch<React.SetStateAction<string>>;
   title: string;
   dateValue?: string;
   showSeachValue?: boolean;
   showexportPdf?: boolean;
   showdatechoosed?: boolean;
   showSearchBtn?: boolean;
   showAddEntity?: boolean;
   handleDateChanged?: (date: string) => void;
   handleButtonExport?: (
      date: string,
      searchValue: string,
      setLoading: (load: boolean) => void,
   ) => void;
   handleButtonSearch?: (
      date: string,
      searchValue: string,
      setLoading: (load: boolean) => void,
   ) => void;

   handleAdd?: () => void;
}

const Headerpanel: React.FC<Props> = ({
   searchValue,
   setSearchValue = test => {},
   title = "",
   dateValue = "",
   showSeachValue = false,
   showexportPdf = false,
   showdatechoosed = false,
   showSearchBtn = false,
   showAddEntity = false,
   handleDateChanged = dateValue => {},
   handleAdd = () => {},
   handleButtonExport = (dateValue, searchValue, loadin) => {},
   handleButtonSearch = (dateValue, searchValue, loadin) => {},
}) => {
   const [loadingButtonexportPdf, setLoadingButtonexportPdf] = useState(false);
   const [loadingButtoneSearch, setLoadingButtonSearch] = useState(false);

   return (
      <Pane display="flex" padding={16} borderRadius={3}>
         <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600}>{title}</Heading>
         </Pane>
         <Pane
            flex={1}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}>
            {showdatechoosed && (
               <Pane marginRight={5}>
                  <div
                     className={`flex items-center w-56 rounded-3xl px-3 text-sm border border-gray-200`}>
                     <input
                        type="date"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           handleDateChanged(e.target.value);
                        }}
                        value={dateValue}
                        className={`h-10 ml-2 flex-1 bg-transparent border-0 outline-none`}
                     />
                  </div>
               </Pane>
            )}
            {showSeachValue && (
               <Pane marginRight={5}>
                  <div
                     className={`flex items-center w-56 rounded-3xl px-3 text-sm border border-gray-200`}>
                     <div className={`relative top-1`}>
                        <Icon icon={SearchOutlined} size={18} />
                     </div>
                     <input
                        type="text"
                        placeholder="Rechercher ..."
                        className={`h-10 ml-2 flex-1 bg-transparent border-0 outline-none`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           setSearchValue(e.target.value);
                        }}
                        value={searchValue}
                     />
                  </div>
               </Pane>
            )}
            {showSearchBtn && (
               <Pane marginRight={5}>
                  <Button
                     isLoading={loadingButtoneSearch}
                     onClick={() => {
                        setLoadingButtonSearch(true);
                        handleButtonSearch(
                           dateValue,
                           searchValue,
                           setLoadingButtonSearch,
                        );
                     }}
                     iconBefore={SearchIcon}>
                     Rechercher
                  </Button>
               </Pane>
            )}
            {showexportPdf && (
               <Pane marginRight={5}>
                  <Button
                     intent="danger"
                     onClick={() => {
                        setLoadingButtonexportPdf(true);
                        handleButtonExport(
                           dateValue,
                           searchValue,
                           setLoadingButtonexportPdf,
                        );
                     }}
                     isLoading={loadingButtonexportPdf}
                     iconBefore={PictureAsPdf}>
                     Exporter
                  </Button>
               </Pane>
            )}

            {showAddEntity && (
               <Pane marginRight={5}>
                  <Button
                     intent="success"
                     onClick={() => {
                        handleAdd();
                     }}
                     iconBefore={Add}>
                     Ajouter
                  </Button>
               </Pane>
            )}
         </Pane>
      </Pane>
   );
};
export default Headerpanel;
