import axios, {type AxiosError, type AxiosResponse} from "axios";
import {toaster} from "evergreen-ui";

// Définir les types de réponses attendues
interface SuccessResponse<T> {
   success: true;
   data: T;
}

interface ErrorResponse {
   success: false;
   error: string;
   data: any;
}

// Service pour effectuer les requêtes HTTP
class AxiosService {
   private baseUrl: string;

   private readonly config: {
      headers: {Authorization: null | string};
   };

   constructor() {
      this.baseUrl = "";
      const storedData = window.sessionStorage.getItem("OTDE");
      const accessToken =
         storedData !== null ? JSON.parse(storedData).accessToken : null;
      this.config = {
         headers: {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            Authorization: `Bearer ${accessToken ?? ""}`,
         },
      };
   }

   setBaseUrlCom(baseuri: string) {
      this.baseUrl = baseuri;
   }

   // Méthode pour effectuer une requête GET
   public async get<T>(
      url: string,
   ): Promise<SuccessResponse<T> | ErrorResponse> {
      try {
         const response: AxiosResponse<T> = await axios.get(
            `${this.baseUrl}${url}`,
            this.config,
         );
         // console.log("config ===> ", this.config);
         return {success: true, data: response.data};
      } catch (error: any) {
         return this.handleErrors(error);
      }
   }

   public async getWithData<T, U>(
      url: string,
      data: U,
   ): Promise<SuccessResponse<T> | ErrorResponse> {
      try {
         const response: AxiosResponse<T> = await axios.get(
            `${this.baseUrl}${url}`,
            {
               params: data, // Utilisez l'option params pour inclure les données dans l'URL
            },
         );
         return {success: true, data: response.data};
      } catch (error: any) {
         return this.handleErrors(error);
      }
   }

   // Méthode pour effectuer une requête POST
   public async post<T, U>(
      url: string,
      data: U,
   ): Promise<SuccessResponse<T> | ErrorResponse> {
      try {
         const response: AxiosResponse<T> = await axios.post(
            `${this.baseUrl}${url}`,
            data,
            this.config,
         );
         return {success: true, data: response.data};
      } catch (error: any) {
         return this.handleErrors(error);
      }
   }

   // Méthode pour effectuer une requête PUT
   public async put<T, U>(
      url: string,
      data: U,
   ): Promise<SuccessResponse<T> | ErrorResponse> {
      try {
         const response: AxiosResponse<T> = await axios.put(
            `${this.baseUrl}${url}`,
            data,
            this.config,
         );
         return {success: true, data: response.data};
      } catch (error: any) {
         return this.handleErrors(error);
      }
   }

   // Ajoutez d'autres méthodes pour les requêtes PUT, DELETE, etc. si nécessaire

   // Méthode pour effectuer une requête DELETE
   public async delete<T>(
      url: string,
   ): Promise<SuccessResponse<T> | ErrorResponse> {
      try {
         const response: AxiosResponse<T> = await axios.delete(
            `${this.baseUrl}${url}`,
            this.config,
         );
         return {success: true, data: response.data};
      } catch (error: any) {
         return this.handleErrors(error);
      }
   }

   // Méthode pour gérer les erreurs Axios
   private handleErrors(error: AxiosError<ErrorResponse>): ErrorResponse {
      const response: {error: string; success: false; data: any} = {
         error: "",
         success: false,
         data: error,
      };
      if (error.response != null) {
         response.success = false;
         response.error = error.response.data.error;
      } else if (error.request !== undefined) {
         response.success = false;
         response.error = "Pas de réponse du serveur";
      } else {
         response.success = false;
         response.error = error.message;
      }
      toaster.danger("OTDE NOTIFICATION", {
         description: response.error,
      });
      return response;
   }
}

export default AxiosService;
