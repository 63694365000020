import {FileCard, type FileRejection, FileUploader, Pane} from "evergreen-ui";
import type React from "react";
import {forwardRef, useCallback, useImperativeHandle, useState} from "react";

interface ImagePreviewProps {
   file: File | null;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({file}) => {
   if (file == null) {
      return null;
   }

   const [imageSrc, setImageSrc] = useState<
      string | ArrayBuffer | null | undefined
   >(null);
   const handleFileRead = useCallback((event: ProgressEvent<FileReader>) => {
      setImageSrc(event.target?.result);
   }, []);

   const previewImage = () => {
      const reader = new FileReader();
      reader.onloadend = handleFileRead;
      reader.readAsDataURL(file);
   };

   previewImage();

   return (
      <div style={{margin: "5px"}}>
         <img
            src={imageSrc as string}
            alt="Preview"
            style={{maxWidth: "100%"}}
         />
      </div>
   );
};

// eslint-disable-next-line react/display-name
const FileUploaderSingleUpload = forwardRef((props, ref) => {
   const [files, setFiles] = useState<File[]>([]);
   const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);

   const handleChange = useCallback((uploadedFiles: File[]) => {
      setFiles([uploadedFiles[0]]);
   }, []);

   const handleRejected = useCallback((rejectedFiles: FileRejection[]) => {
      setFileRejections([rejectedFiles[0]]);
   }, []);

   const handleRemove = useCallback(() => {
      setFiles([]);
      setFileRejections([]);
   }, []);

   const getSelectedFile = () => {
      return files.length > 0 ? files[0] : null;
   };

   useImperativeHandle(ref, () => ({
      getSelectedFile,
   }));

   // Fournir la fonction getSelectedFile via ref

   return (
      <Pane>
         <FileUploader
            label="Téleverser votre fichier"
            description="Vous pouvez ajouter un seul fichier."
            maxSizeInBytes={50 * 1024 ** 2}
            maxFiles={1}
            onChange={handleChange}
            onRejected={handleRejected}
            renderFile={file => {
               const {name, size, type} = file;

               const fileRejection = fileRejections.find(
                  rejection => rejection.file === file,
               );

               return (
                  <>
                     <FileCard
                        key={name}
                        isInvalid={fileRejection != null}
                        name={name}
                        onRemove={handleRemove}
                        sizeInBytes={size}
                        type={type}
                     />
                     <ImagePreview file={file} />
                  </>
               );
            }}
            values={files}
         />
      </Pane>
   );
});

export default FileUploaderSingleUpload;
