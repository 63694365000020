import {useState} from "react";
import {NavLink} from "react-router-dom";
import logo from "../../images/logo-map-charity.png";
import ErrorComponent from "../../components/ErrorComponent";
import {translate} from "../../i18n/translate";
import {
   EnvelopeIcon,
   MapPinIcon,
   PhoneIcon,
   UserIcon,
} from "@heroicons/react/20/solid";
import InputIcon from "../../components/InputIcon";
import {Password} from "@mui/icons-material";
import {UtilisateurService} from "../../services/utilisateur.service";
import {toaster} from "evergreen-ui";

function InscriptionPage() {
   const [errorMessage, setErrorMessage] = useState("");
   const [showError, setShowError] = useState(false);
   const [name, setName] = useState("");
   const [firstName, setFirstName] = useState("");
   const [email, setEmail] = useState("");
   const [username, setUsername] = useState("");
   const [adress, setAdress] = useState("");
   const [phoneNumber, setPhoneNumber] = useState("");
   const [passwordConfirmer, setPasswordConfirmer] = useState("");
   const [password, setPassword] = useState("");

   const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
   const validPhoneNumber =
      /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
   const isFormValid = () => {
      if (username === "" || email === "" || name === "" || firstName === "") {
         setErrorMessage(translate("TR_120"));
         return false;
      } else if (!validEmail.test(email)) {
         setErrorMessage(translate("TR_122"));
         return false;
      } else if (phoneNumber !== "" && !validPhoneNumber.test(phoneNumber)) {
         setErrorMessage(translate("TR_123"));
         return false;
      }
      if (password !== "" && password !== passwordConfirmer) {
         setErrorMessage(translate("TR_1242"));
         return false;
      } else {
         setErrorMessage("");
         return true;
      }
   };

   const submitLogin = (event: any) => {
      event.preventDefault();
      if (isFormValid()) {
         setShowError(false);
         void UtilisateurService.postRegisterUser({
            username,
            email,
            nom: firstName,
            prenom: name,
            adresse: adress,
            password,
            roles: ["USER"],
            telephone: phoneNumber,
            id: undefined,
         }).then(res => {
            if (res.success) {
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , votre compte a été créé ",
               });
               setFirstName("");
               setEmail("");
               setName("");
               setAdress("");
               setPhoneNumber("");
               setUsername("");
               setPasswordConfirmer("");
               setPassword("");
               const port = window.location.port;
               window.location.href = `//${window.location.hostname}${
                  port !== "" ? `:${port}` : ""
               }/connexion`;
            }
         });
      } else {
         setShowError(true);
         // Showing error
      }
   };

   return (
      <div
         className="text-center bg-no-repeat bg-center bg-cover flex flex-col h-screen bg-green-500/50"
         style={{
            backgroundImage: `url(/images/inscription-bg.jpg)`,
         }}>
         <div className="bg-gradient-to-br from-green-950/80 to-green-500/70 flex items-center justify-center h-screen">
            <div className="text-center py-20">
               <img src={logo} className="w-16 h-16 mx-auto" />
               {showError ? (
                  <div className="py-4">
                     <ErrorComponent errorMessage={errorMessage} />
                  </div>
               ) : null}

               <form className="px-8 pt-6 pb-8 mb-4 z-0" onSubmit={submitLogin}>
                  <InputIcon
                     inputValue={name}
                     setInputValue={setName}
                     code="TR_163"
                     type="text"
                     id="name"
                     Icon={UserIcon}
                     obligatory={true}
                     maxLength={100}
                  />
                  <InputIcon
                     inputValue={firstName}
                     setInputValue={setFirstName}
                     code="TR_164"
                     type="text"
                     id="firstname"
                     Icon={UserIcon}
                     obligatory={true}
                     maxLength={100}
                  />
                  <InputIcon
                     inputValue={email}
                     setInputValue={setEmail}
                     code="TR_142"
                     type="text"
                     id="email"
                     Icon={EnvelopeIcon}
                     obligatory={true}
                     maxLength={100}
                  />
                  <InputIcon
                     inputValue={username}
                     setInputValue={setUsername}
                     code="TR_165"
                     type="text"
                     id="username"
                     Icon={UserIcon}
                     obligatory={true}
                     maxLength={100}
                  />
                  <InputIcon
                     inputValue={adress}
                     setInputValue={setAdress}
                     code="TR_166"
                     type="text"
                     id="adress"
                     Icon={MapPinIcon}
                     obligatory={false}
                     maxLength={100}
                  />
                  <InputIcon
                     inputValue={phoneNumber}
                     setInputValue={setPhoneNumber}
                     code="TR_167"
                     type="text"
                     id="phoneNumber"
                     Icon={PhoneIcon}
                     obligatory={false}
                     maxLength={30}
                  />
                  <InputIcon
                     inputValue={password}
                     setInputValue={setPassword}
                     code="TR_1677"
                     type="password"
                     id="password"
                     Icon={Password}
                     obligatory={false}
                     maxLength={30}
                  />
                  <InputIcon
                     inputValue={passwordConfirmer}
                     setInputValue={setPasswordConfirmer}
                     code="TR_1678"
                     type="password"
                     id="passwordC"
                     Icon={Password}
                     obligatory={false}
                     maxLength={30}
                  />

                  <div className="flex pt-8 items-center">
                     <button
                        type="submit"
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-24 md:px-32 rounded-full mx-auto focus:outline-none focus:shadow-outline">
                        {translate("TR_8")}
                     </button>
                  </div>
                  <div className="flex pt-2">
                     <NavLink
                        className="inline-block align-baseline font-bold text-sm text-white ml-auto"
                        to="/connexion">
                        {translate("TR_95")}
                        <span className="text-gray-700">
                           {translate("TR_96")}
                        </span>
                     </NavLink>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}

export default InscriptionPage;
