import NavigationBar from "../components/NavigationBar";
import {Outlet} from "react-router-dom";
import "./css/landing.scss";

export const LandingPageLayout = () => {
   return (
      <>
         <NavigationBar />
         <Outlet />
      </>
   );
};
