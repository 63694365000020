import {useState} from "react";
import {translate} from "../../../i18n/translate";

const tabsList = [
   {
      label: translate("TR_147"),
      text: translate("TR_148")?.concat("."),
   },
   {
      label: translate("TR_149"),
      text: translate("TR_150")?.concat("."),
   },
   {
      label: translate("TR_151"),
      text: translate("TR_152")?.concat("."),
   },
   {
      label: translate("TR_153"),
      text: translate("TR_154")?.concat("."),
   },
];

const TabButton = ({children, active, onSelect}: any) => {
   return (
      <button
         onClick={() => onSelect()}
         className={`
            text-sm text-white transition-all duration-300 outline-none
            ${active === true ? "bg-[#5ED853]" : ""}
            py-3 px-4 rounded-3xl`}>
         {children}
      </button>
   );
};

const MissionsValeursSection = () => {
   const [activeTab, setActiveTab] = useState(translate("TR_147"));
   return (
      <>
         <section className="py-20 bg-gray-100">
            <div className="container max-w-2xl px-3 mx-auto">
               <h3 className="text-lg text-gray-500 font-bold">
                  {translate("TR_155")}
               </h3>
               <div className="mt-3 rounded-md overflow-hidden shadow-md">
                  <div className="bg-[#23C55E] py-3 px-4 flex justify-center gap-0 flex-col md:gap-7 md:flex-row">
                     {tabsList.map((tabItem, idx) => (
                        <TabButton
                           key={idx + 1}
                           onSelect={() => {
                              setActiveTab(tabItem.label);
                           }}
                           active={activeTab === tabItem.label}>
                           {tabItem.label}
                        </TabButton>
                     ))}
                  </div>
                  <div className="bg-white p-6">
                     {tabsList.map((tabItem, idx) => (
                        <p
                           key={idx + 1}
                           className={`text-base text-gray-500 text-light text-center leading-7 ${
                              activeTab === tabItem.label ? "block" : "hidden"
                           }`}>
                           {tabItem.text}
                        </p>
                     ))}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default MissionsValeursSection;
