import type {DatatableUtilisateurType} from "../types/datatable-utilisateur.type";
import {type UtilisateurDtoType} from "../types/utilisateur-dto.type";
import {type UtilisateurType} from "../types/utilisateur.type";
import {UtilisateurMockStripe} from "../mocks/utilisateur.mock";
import {type ResponseEntityType} from "../types/response-entity.type";
import URL_MICRO_SERVICE from "./URL";
import AxionService from "./axion.service";
import {type PagginationQueryRequestType} from "../types/paggination-query-request.type";
import {type DatatableResponseType} from "../types/datatable-response.type";
import type {LoginType} from "../types/login.type";
import {type ResetPasswordType} from "../types/reset-password.type";
import {type UpdatePasswordType} from "../types/update-password.type";

const URL_MICRO_COMMAND = URL_MICRO_SERVICE.UTILISATEUR_COMMANDE_SERVICE;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const URL_MICRO_QUERY = URL_MICRO_SERVICE.UTILISATEUR_QUERY_SERVICE;
/*
 * @Instance axios
 * */
const axiosService = new AxionService();
export const UtilisateurService = {
   async getUtilisateurDashboadList(): Promise<UtilisateurType[]> {
      return await new Promise((resolve: any) => {
         setTimeout(() => resolve(UtilisateurMockStripe), 1000);
      });
   },
   async getUtilisateurList(
      pagginationQueryRequestType: PagginationQueryRequestType,
   ): Promise<DatatableUtilisateurType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get<DatatableResponseType<UtilisateurType>>(
               `pageUtilisateur?pageNo=${pagginationQueryRequestType.pageNo}&pageSize=${pagginationQueryRequestType.pageSize}&sortBy=${pagginationQueryRequestType.sortBy}&sortDir=${pagginationQueryRequestType.sortDir}`,
            )
            .then(res => {
               const returnArrayDatatable: DatatableUtilisateurType = {
                  utilisateurListe: res.data.content,
                  currentPage: res.data.pageNo,
                  totalRow: res.data.totalElements,
               };
               resolve(returnArrayDatatable);
            })
            .catch(error => reject(error));
      });
   },

   async postRegisterUser(
      user: UtilisateurType,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("create", user)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   async updateUtilisateur(
      utilisateur: UtilisateurType,
      id: string,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .put<ResponseEntityType<string>, UtilisateurType>(
               `update/${id}`,
               utilisateur,
            )
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
   paginate(
      arrayPaginate: UtilisateurDtoType[],
      numeroPage: number,
      elementsParPage: number,
   ): UtilisateurDtoType[] {
      const indiceDebut = (numeroPage - 1) * elementsParPage;
      const indiceFin = indiceDebut + elementsParPage;
      const elementsDeLaPage = arrayPaginate.slice(indiceDebut, indiceFin);
      return elementsDeLaPage;
   },
   async getUtilisateur(
      id: string,
   ): Promise<ResponseEntityType<UtilisateurType>> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get(`${id}`)
            .then(res => {
               return resolve(res);
            })
            .catch(error => reject(error));
      });
   },
   deleteUtilisateur: async (
      id: string,
   ): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .delete(`${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
   login: async (utilisateur: LoginType) => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("sign-in", utilisateur)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   logout: async (): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get("logout")
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   sendEmail: async (email: string): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("send-email", {email})
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
   async verifyResetCode(
      resetPassword: ResetPasswordType,
   ): Promise<ResponseEntityType<UtilisateurType>> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .getWithData(`verify-password`, resetPassword)
            .then(res => {
               return resolve(res);
            })
            .catch(error => reject(error));
      });
   },

   updatePassword: async (utilisateur: UpdatePasswordType) => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("update-password", utilisateur)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
};
