import {type JSX, useEffect, useState} from "react";
import $ from "jquery";
import {v4 as uuidv4} from "uuid";

interface DropdownProps {
   title: string;
   children: JSX.Element[] | JSX.Element;
}

export function Dropdown({children, title}: DropdownProps) {
   const [display, setDisplay] = useState(false);
   const [uuid] = useState(uuidv4());
   useEffect(() => {
      $(document).on("click", e => {
         const targetElement = $(e.target);
         if (
            targetElement.closest(`#button${uuid}`).length === 0 &&
            targetElement.closest(`#dropdown${uuid}`).length === 0
         ) {
            setDisplay(false);
         }
      });
   }, []);
   return (
      <div className="relative inline-block">
         <div>
            <button
               onClick={() => {
                  setDisplay(!display);
               }}
               id={`button${uuid}`}
               type="button"
               className="inline-flex justify-center bg-transparent text-white"
               aria-expanded="true"
               aria-haspopup="true">
               {title}
               <svg
                  className="-mr-1 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true">
                  <path
                     fillRule="evenodd"
                     d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                     clipRule="evenodd"
                  />
               </svg>
            </button>
         </div>
         <div
            className={
               display
                  ? "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropdown-block"
                  : "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropdown-none"
            }
            role="menu"
            id={`dropdown${uuid}`}
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}>
            <div
               className="py-1"
               role="none"
               onClick={() => {
                  setDisplay(!display);
               }}>
               {children}
            </div>
         </div>
      </div>
   );
}
