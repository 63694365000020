import {CartesianGrid, Line, LineChart, XAxis, YAxis} from "recharts";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {type StatsDashboadCordonneType} from "../../../types/stats-dashboad-cordonne.type";
import {StatistiqueService} from "../../../services/statistique.service";
import ContainerOTDE from "../container/ContainerOTDE";

interface StatistiqueDonProps {
   dateChoosed: any;
}

function CustomYAxisTickFormatter(value: number): string {
   return `${value}k`;
}

function CustomXAxisTickFormatter(value: number): string {
   const month = ["Jan", "Fev", "Avril"];
   return month[value];
}

const StatistiqueDon: React.FC<StatistiqueDonProps> = ({dateChoosed}) => {
   const [data, setData] = useState<StatsDashboadCordonneType[]>([]);
   const [loading, setLoading] = useState<boolean>(true);

   useEffect(() => {
      setData([]);
      getFetchData();
   }, [dateChoosed]);

   const getFetchData = () => {
      setLoading(true);
      StatistiqueService.getCordonneeStatistiques(dateChoosed)
         .then(data => {
            setData(data);
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };

   return (
      <ContainerOTDE line={4} loading={loading}>
         <LineChart width={500} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickFormatter={CustomXAxisTickFormatter} />
            <YAxis tickFormatter={CustomYAxisTickFormatter} />
            <Line
               isAnimationActive={false}
               type="monotone"
               dataKey="y"
               stroke="#1DBE00"
               activeDot={{r: 15}}
            />
         </LineChart>
      </ContainerOTDE>
   );
};
StatistiqueDon.propTypes = {
   dateChoosed: PropTypes.any.isRequired,
};

export default StatistiqueDon;
