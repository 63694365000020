import {useEffect, useState} from "react";
import {Banner} from "../../components/Banner";
import ProjectComponent from "../../components/ProjectComponent";
import {translate} from "../../i18n/translate";
import {DonationsPresentation} from "./sections/donations-presentation";
import {ProjetService} from "../../services/projet.service";
import ContainerOTDE from "../../admin/component/container/ContainerOTDE";
import type {ProjetType} from "../../types/projet.type";

function NosProjets() {
   const projectLength = 6;
   const initialChoosedArray: number[] = Array(projectLength).fill(0);
   const [projets, setProjets] = useState<ProjetType[]>([]);
   const [choosedArray, setChoosedArray] = useState(initialChoosedArray);
   const [loading, setLoading] = useState(true);
   const [projetCovers, setProjetCovers] = useState<string>("");

   useEffect(() => {
      getListeprojets();
   }, []);

   const getListeprojets = () => {
      setLoading(true);
      void ProjetService.getprojetsListeForLanding().then(res => {
         if (res.success) {
            setProjets(res.data);
            res.data.forEach(projet => {
               void getImageProjet(projet.id).then(r => {});
            });
         }
         setLoading(false);
      });
   };

   const getImageProjet = async (id: string | undefined) => {
      try {
         const res = await ProjetService.getImageProjet(id);
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         // @ts-expect-error
         setProjetCovers(prevCovers => [...prevCovers, res]);
      } catch (error) {
         console.error(
            "Erreur lors de la récupération de l'image du projet",
            error,
         );
      }
   };

   return (
      <>
         <header>
            <Banner
               url="/images/ecole.jpg"
               title={translate("TR_109")}
               subTitle={translate("TR_110")}
            />
            <DonationsPresentation />
         </header>
         <div className="container mx-auto">
            <ContainerOTDE loading={loading} line={10}>
               <>
                  <section className=" md:h-full flex items-center text-gray-600">
                     <div>
                        <div className="flex flex-wrap ">
                           {projets.map((projet, idx) => {
                              return (
                                 <ProjectComponent
                                    srcImg={projetCovers[idx]}
                                    projectTitle={projet.titre}
                                    projectObjective={
                                       projet.objectif === undefined
                                          ? ""
                                          : projet.objectif
                                    }
                                    projectDescription={projet.description}
                                    index={
                                       projet.id === undefined ? "" : projet.id
                                    }
                                    key={idx}
                                    setSelectedProject={setChoosedArray}
                                    selectedProject={choosedArray}
                                 />
                              );
                           })}
                        </div>
                     </div>
                  </section>
               </>
            </ContainerOTDE>
         </div>
      </>
   );
}

export default NosProjets;
