import Headerpanel from "../../component/headerpanel";
import {useEffect, useState} from "react";
import {
   Button,
   Label,
   Pane,
   Textarea,
   TextInputField,
   toaster,
} from "evergreen-ui";
import {type UtilisateurType} from "../../../types/utilisateur.type";
import {useForm} from "react-hook-form";
import {Save} from "@mui/icons-material";
import {UtilisateurService} from "../../../services/utilisateur.service";
import {useNavigate, useParams} from "react-router-dom";
import {type ResponseEntityType} from "../../../types/response-entity.type";

export const ManageUtilisateur = () => {
   const HeaderTitle = "Gestion utilisateur";
   const {id} = useParams();
   const navigate = useNavigate();

   useEffect(() => {
      if (id !== undefined) {
         getUtilisateur(id);
      }
   }, []);

   const [saveingLoading, setSaveInloading] = useState<boolean>(false);

   const [userForm, setForm] = useState<UtilisateurType>({
      id: undefined,
      nom: "",
      prenom: "",
      username: "",
      email: "",
      roles: ["ADMIN"],
      password: "123456",
      telephone: "",
      adresse: "",
   });

   const {handleSubmit} = useForm();
   const getUtilisateur = (id: string) => {
      void UtilisateurService.getUtilisateur(id).then(
         (res: ResponseEntityType<UtilisateurType>) => {
            if (res.success && typeof res.data === "object") {
               setForm({...res.data});
            }
         },
      );
   };

   function onSubmit(data: any): any {
      setSaveInloading(true);
      if (id !== undefined) {
         // TODO update
         void UtilisateurService.updateUtilisateur(userForm, id).then(res => {
            if (res.success) {
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , le  compte a été modifié ",
               });
               setTimeout(() => {
                  navigate("/admin/utilisateurs");
               }, 2000);
            }
         });
      } else {
         void UtilisateurService.postRegisterUser(userForm).then(res => {
            if (res.success) {
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , le  compte a été créé ",
               });
               setForm(prevState => ({
                  ...prevState,
                  id: undefined,
                  nom: "",
                  prenom: "",
                  username: "",
                  email: "",
                  roles: ["ADMIN"],
                  password: "123456",
                  telephone: "",
                  adresse: "",
               }));

               setTimeout(() => {
                  navigate("/admin/utilisateurs");
               }, 2000);
            }
         });
      }

      setSaveInloading(false);
   }

   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Headerpanel
                  title={HeaderTitle}
                  searchValue={""}
                  setSearchValue={() => {}}
               />
               <Pane padding={16} flex={1} borderRadius={3}>
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <Pane display={"flex"} flexDirection={"column"}>
                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"row"}
                           justifyContent={"space-evenly"}>
                           <TextInputField
                              isInvalid={userForm.nom === ""}
                              value={userForm.nom}
                              name={"nom"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    nom: e.target.value,
                                 }));
                              }}
                              placeholder={"Entrez le nom de l'utilisateur"}
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le nom de l'utilisateur"
                           />

                           <TextInputField
                              isInvalid={userForm.prenom === ""}
                              value={userForm.prenom}
                              name={"prenom"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    prenom: e.target.value,
                                 }));
                              }}
                              placeholder={"Entrez le prenom de l'utilisateur"}
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le prenom de l'utilisateur"
                           />
                        </Pane>
                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"row"}
                           justifyContent={"space-evenly"}>
                           <TextInputField
                              isInvalid={userForm.telephone === ""}
                              value={userForm.telephone}
                              name={"telephone"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    telephone: e.target.value,
                                 }));
                              }}
                              placeholder={
                                 "Entrez le telephone de l'utilisateur"
                              }
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le telephone de l'utilisateur"
                           />

                           <TextInputField
                              isInvalid={userForm.username === ""}
                              value={userForm.username}
                              name={"telephone"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    username: e.target.value,
                                 }));
                              }}
                              placeholder={
                                 "Entrez le username de l'utilisateur"
                              }
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le username de l'utilisateur"
                           />
                        </Pane>

                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"row"}
                           justifyContent={"space-evenly"}>
                           <TextInputField
                              isInvalid={userForm.email === ""}
                              value={userForm.email}
                              name={"telephone"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    email: e.target.value,
                                 }));
                              }}
                              placeholder={"Entrez l'email de l'utilisateur"}
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir l'email de l'utilisateur"
                           />
                        </Pane>
                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"column"}>
                           <Label
                              htmlFor="textarea-2"
                              marginBottom={4}
                              display="block">
                              Adresse utilisateur
                           </Label>
                           <Textarea
                              id="textarea-2"
                              placeholder=" Veuillez ajoutez l'adresse ."
                              isInvalid={userForm.adresse === ""}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    adresse: e.target.value,
                                 }));
                              }}
                              value={userForm.adresse}
                           />
                        </Pane>
                        <Pane marginTop={10}>
                           <Button
                              marginY={8}
                              marginRight={12}
                              iconAfter={Save}
                              isLoading={saveingLoading}
                              intent={"success"}>
                              Valider
                           </Button>
                        </Pane>
                     </Pane>
                  </form>
               </Pane>
            </dl>
         </div>
      </div>
   );
};
