interface TableSkeletonInterface {
   lines: number;
}

export const TableSkeleton = (props: TableSkeletonInterface) => {
   const {lines} = props;

   return (
      <>
         <ul className={`flex flex-col gap-3 w-full`}>
            <li
               className={`h-14 bg-design-gray w-full animate-pulse rounded-md`}></li>
            {new Array(lines).fill(1).map((item, index) => (
               <li
                  key={index}
                  className={`h-9 bg-design-gray w-full animate-pulse rounded-md`}></li>
            ))}
         </ul>
      </>
   );
};
