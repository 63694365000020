export enum ProjetStatus {
   CREATED,
   ACTIVETED,
   SUSPENDU,
   APDATED,
}

export interface ProjetType {
   titre: string;
   description: string;
   isActive?: ProjetStatus;
   objectif?: string;
   cover: string;
   id?: string;
}
