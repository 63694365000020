import type {DatatableProjetType} from "../types/datatable-projet.type";
import {type ProjetType} from "../types/projet.type";
import {ProjetMockStripe} from "../mocks/projet.mock";
import URL_MICRO_SERVICE from "./URL";
import AxionService from "./axion.service";
import {type ResponseProjetListeLpType} from "../types/response-projet-liste-lp.type";
import {type PagginationQueryRequestType} from "../types/paggination-query-request.type";
import {type DatatableResponseType} from "../types/datatable-response.type";
import {type ContactType} from "../types/contact.type";
import {type ResponseEntityType} from "../types/response-entity.type";

/**
 * @Constante du micro service
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const URL_MICRO_COMMAND = URL_MICRO_SERVICE.PROJET_COMMANDE_SERVICE;
const URL_MICRO_QUERY = URL_MICRO_SERVICE.PROJET_QUERY_SERVICE;
/*
 * @Instance axios
 * */
const axiosService = new AxionService();

export const ProjetService = {
   async getProjetDashboadList(): Promise<ProjetType[]> {
      return await new Promise((resolve: any) => {
         setTimeout(() => resolve(ProjetMockStripe), 1000);
      });
   },
   async getprojetsListeForLanding(): Promise<ResponseProjetListeLpType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get("projets")
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
   async getListeProjets(
      pagginationQueryRequestType: PagginationQueryRequestType,
   ): Promise<DatatableProjetType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get<DatatableResponseType<ContactType>>(
               `pageProjets?pageNo=${pagginationQueryRequestType.pageNo}&pageSize=${pagginationQueryRequestType.pageSize}&sortBy=${pagginationQueryRequestType.sortBy}&sortDir=${pagginationQueryRequestType.sortDir}`,
            )
            .then(res => {
               const returnArrayDatatable: DatatableProjetType = {
                  projetListe: res.data.content,
                  currentPage: res.data.pageNo,
                  totalRow: res.data.totalElements,
               };
               resolve(returnArrayDatatable);
            })
            .catch(error => reject(error));
      });
   },

   async postCreateProjet(
      projet: ProjetType,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("create", projet)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   async uploadProjetCover(
      id: string,
      file: File,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      const formData = new FormData();
      formData.append("file", file);
      try {
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         // @ts-expect-error
         const res = await axiosService.post(`${id}`, formData, {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         });
         return res.data;
      } catch (error) {
         console.error("Erreur lors de l'envoi du fichier", error);
         throw error;
      }
   },

   async updateProjet(
      projet: ProjetType,
      id: string,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .put<ResponseEntityType<string>, ProjetType>(`update/${id}`, projet)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
   async getProjetId(id: string): Promise<ResponseEntityType<ProjetType>> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get(`${id}`)
            .then(res => {
               return resolve(res);
            })
            .catch(error => reject(error));
      });
   },

   async getImageProjet(id: string | undefined) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${URL_MICRO_QUERY}/image/${id}`;
   },

   deleteProjet: async (id: string): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .delete(`${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
};
