import {NavLink} from "react-router-dom";
import logo from "../images/logo-otde.png";
import {routes} from "../router/routes";
import {useEffect, useState} from "react";
import AuthDropdown from "./AuthDropdown";
import LanguageDropdown from "./LanguageDropdown";
import DropdownMobile from "./DropdownMobile";
import {v4 as uuidv4} from "uuid";

function NavigationBar(this: any) {
   const width = window.innerWidth;
   const [isScrolled, setIsScrolled] = useState(false);
   const [routeCustomised, setRouteCustomised] = useState(routes as any);
   useEffect(() => {
      setRouteCustomised(
         routes.filter(
            route =>
               route.path !== "/connexion" &&
               route.path !== "/inscription" &&
               route.path !== "/forgot-password",
         ),
      );
      const handleScroll = () => {
         const windowHeight = window.innerHeight;
         const documentHeight = document.documentElement.scrollHeight;
         const scrollThreshold = (documentHeight - windowHeight) / 2;
         if (window.scrollY >= scrollThreshold) {
            setIsScrolled(true);
         } else {
            setIsScrolled(false);
         }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, []);
   return (
      <>
         {width > 800 ? (
            <div className="fixed top-0 left-0 right-0 z-10">
               <div
                  className={
                     isScrolled
                        ? "flex flex-row py-4 justify-center items-center bg-primary"
                        : "flex flex-row py-4 justify-center items-center bg-transparent"
                  }>
                  <div className="flex-initial">
                     <img className="w-32" src={logo} alt="Logo"></img>
                  </div>
                  <div className="flex-initial space-x-8 uppercase text-xs text-white border-solid">
                     {routeCustomised.map((route: any) => (
                        <NavLink
                           key={uuidv4()}
                           to={route.path}
                           className="hover:text-green-600">
                           {route.name}
                        </NavLink>
                     ))}
                     <AuthDropdown></AuthDropdown>
                     <LanguageDropdown></LanguageDropdown>
                  </div>
               </div>
            </div>
         ) : (
            <div className="fixed top-0 left-0 right-0 z-10">
               <div
                  className={
                     isScrolled
                        ? "flex flex-row py-4 justify-center items-center bg-green-500"
                        : "flex flex-row py-4 justify-center items-center"
                  }>
                  <div className="flex-initial">
                     <img className="w-24" src={logo} alt="Logo"></img>
                  </div>
                  <div className="flex-initial uppercase text-xs text-white border-solid ml-auto mr-4">
                     <DropdownMobile></DropdownMobile>
                  </div>
               </div>
            </div>
         )}
      </>
   );
}

export default NavigationBar;
