import type {DatatableDonType} from "../types/datatable-don.type";
import {type DonDtoType} from "../types/don-dto.type";
import {DonListeDto} from "../mocks/don-liste-dto.type";

import {type PagginationQueryRequestType} from "../types/paggination-query-request.type";
import {type DatatableResponseType} from "../types/datatable-response.type";
import URL_MICRO_SERVICE from "./URL";
import AxionService from "./axion.service";
import {type ResponseEntityType} from "../types/response-entity.type";
import {type PaiementType} from "../types/paiement.type";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const URL_MICRO_COMMAND = URL_MICRO_SERVICE.DON_COMMANDE_SERVICE;
const URL_MICRO_QUERY = URL_MICRO_SERVICE.DON_QUERY_SERVICE;
/*
 * @Instance axios
 * */
const axiosService = new AxionService();

export const DonService = {
   // temporairement ...
   async getDonListe(
      searchText: string,
      pageSize: number,
   ): Promise<DatatableDonType> {
      return await new Promise((resolve: any) => {
         const elementPage = 10;
         const data: DonDtoType[] = DonService.paginate(
            DonListeDto,
            pageSize,
            elementPage,
         );
         const returnArrayDatatable: DatatableDonType = {
            donListe: data,
            currentPage: pageSize,
            totalRow: DonListeDto.length,
         };
         setTimeout(() => resolve(returnArrayDatatable), 1000);
      });
   },
   async getListedon(
      pagginationQueryRequestType: PagginationQueryRequestType,
   ): Promise<DatatableDonType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get<DatatableResponseType<DonDtoType>>(
               `pageDon?pageNo=${pagginationQueryRequestType.pageNo}&pageSize=${pagginationQueryRequestType.pageSize}&sortBy=${pagginationQueryRequestType.sortBy}&sortDir=${pagginationQueryRequestType.sortDir}`,
            )
            .then(res => {
               const returnArrayDatatable: DatatableDonType = {
                  donListe: res.data.content,
                  currentPage: res.data.pageNo,
                  totalRow: res.data.totalElements,
               };
               resolve(returnArrayDatatable);
            })
            .catch(error => reject(error));
      });
   },
   paginate(
      arrayPaginate: DonDtoType[],
      numeroPage: number,
      elementsParPage: number,
   ): DonDtoType[] {
      const indiceDebut = (numeroPage - 1) * elementsParPage;
      const indiceFin = indiceDebut + elementsParPage;
      const elementsDeLaPage = arrayPaginate.slice(indiceDebut, indiceFin);
      return elementsDeLaPage;
   },

   getDonById: async (id: string) => {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get(`${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   updateDon: async (don: PaiementType, id: string) => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post(`update/${id}`, don)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   addDon: async (
      paiement: PaiementType,
   ): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post(`pay`, paiement)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   deleteDon: async (id: string): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .delete(`${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
};
