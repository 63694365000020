import type {UtilisateurType} from "../types/utilisateur.type";

export const UtilisateurMockStripe: UtilisateurType[] = [
   {
      id: 1,
      nom: "Doe",
      prenom: "John",
      username: "johnodoe",
      email: "johndoe@email.com",
      telephone: "+33 612345678",
      password: "john",
      roles: ["Président directeur"],
   },
   {
      id: 2,
      nom: "Sympa",
      prenom: "Une Personne",
      username: "sympa",
      email: "sympa@personne.com",
      telephone: "+33 612387975",
      password: "sympa",
      roles: ["Membre"],
   },
   {
      id: 3,
      nom: "Zahigro",
      prenom: "Ulrich",
      username: "ulrich",
      email: "ulrich@noemail.com",
      telephone: "+33 025465215756",
      password: "ulrich",
      roles: ["Membre"],
   },
];
