import ContainerOTDE from "../../admin/component/container/ContainerOTDE";
import {Banner} from "../../components/Banner";
import {translate} from "../../i18n/translate";
import {useEffect, useState} from "react";
import {EquipeService} from "../../services/equipe.service";
import type {EquipeType} from "../../types/equipe.type";

const NotreEquipe = () => {
   const [loading, setLoading] = useState(true);
   const [equipes, setEquipes] = useState<EquipeType[]>([]);
   const [teamCovers, setTeamCovers] = useState<string>("");

   useEffect(() => {
      getListeEquipe();
   }, []);

   const getListeEquipe = () => {
      void EquipeService.getequipesListeForLanding().then(res => {
         if (res.success) {
            setEquipes(res.data);
            // Précharge les images pour chaque membre de l'équipe
            res.data.forEach(menbre => {
               void getImageTeam(menbre.id).then(r => {});
            });
         }
         setLoading(false);
      });
   };

   const getImageTeam = async (id: string | undefined) => {
      try {
         const res = await EquipeService.getImageTeam(id);
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         // @ts-expect-error
         setTeamCovers(prevCovers => [...prevCovers, res]);
      } catch (error) {
         console.error(
            "Erreur lors de la récupération de l'image de l'équipe",
            error,
         );
         // Gérer l'erreur si nécessaire
      }
   };

   return (
      <header>
         <Banner
            url="/images/image-4.jpg"
            title={translate("TR_114")}
            subTitle={translate("TR_115")}
         />
         <section className="container px-3 py-20 mx-auto">
            <div className="max-w-3xl mx-auto text-center">
               <h2 className="text-gray-800 text-4xl font-bold">
                  {translate("TR_116")}
               </h2>
               <p className="mt-7 text-lg text-gray-700 font-thin">
                  {translate("TR_117")}
               </p>
            </div>
         </section>
         <div className=" py-20 grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            <ContainerOTDE loading={loading} line={10}>
               {equipes.map((menbre, index) => {
                  return (
                     // eslint-disable-next-line react/jsx-key
                     <div className="flex items-center">
                        <div className="w-1/2">
                           <img
                              className="w-full h-auto bg-gray-500 shadow-2xl shadow-gray-500/50"
                              src={teamCovers[index]}
                              alt={`Team Member ${index + 1}`}
                           />
                        </div>
                        <div className="w-1/2 px-5">
                           <h1 className="text-4xl py-2">{menbre.nom}</h1>
                           <h2 className="text-2xl text-black py-3">
                              {menbre.function}
                           </h2>
                           <p className="py-3 text-gray-500">
                              {" "}
                              {menbre.description}
                           </p>
                        </div>
                     </div>
                  );
               })}
            </ContainerOTDE>
         </div>
      </header>
   );
};
export default NotreEquipe;
