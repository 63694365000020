import Headerpanel from "../../component/headerpanel";
import {useEffect, useRef, useState} from "react";
import {
   Button,
   Label,
   Pane,
   Textarea,
   TextInputField,
   toaster,
} from "evergreen-ui";
import {type EquipeType} from "../../../types/equipe.type";
import {useForm} from "react-hook-form";
import {Save} from "@mui/icons-material";
import {EquipeService} from "../../../services/equipe.service";
import {useNavigate, useParams} from "react-router-dom";
import {type ResponseEntityType} from "../../../types/response-entity.type";
import FileUploaderSingleUpload from "../../component/input/FileInput";

export const ManageEquipe = () => {
   const HeaderTitle = "Gestion Equipe";
   const {id} = useParams();
   const navigate = useNavigate();
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-expect-error
   const fileUploaderRef = useRef<FileUploaderSingleUpload>(null);

   useEffect(() => {
      if (id !== undefined) {
         getMenbre(id);
      }
   }, []);

   const [saveingLoading, setSaveInloading] = useState<boolean>(false);

   const [userForm, setForm] = useState<EquipeType>({
      id: "",
      nom: "",
      username: "",
      userCover: "",
      phone: "",
      description: "",
      function: "",
      localDate: "",
      status: "",
   });

   const {handleSubmit} = useForm();

   const getMenbre = (id: string) => {
      void EquipeService.getTeams(id).then(
         (res: ResponseEntityType<EquipeType>) => {
            if (res.success && typeof res.data === "object") {
               setForm({...res.data});
            }
         },
      );
   };

   function onSubmit(data: any): any {
      setSaveInloading(true);
      if (id !== undefined) {
         void EquipeService.updateTeams(userForm, id).then(res => {
            if (res.success) {
               const selectedFile = fileUploaderRef.current?.getSelectedFile();
               if (selectedFile !== null) {
                  void EquipeService.uploadTeamCover(id, selectedFile).then(
                     res => {
                        // Gérer la réponse si nécessaire
                     },
                  );
               }
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , le menbre  a été modifié ",
               });
               setTimeout(() => {
                  navigate("/admin/equipe");
               }, 2000);
            }
         });
      } else {
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         let teamId = "";
         void EquipeService.postCreateTeam(userForm).then(res => {
            if (res.success) {
               teamId = res.data;
               const selectedFile = fileUploaderRef.current?.getSelectedFile();
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , le  menbre a été créé ",
               });
               if (selectedFile !== null) {
                  void EquipeService.uploadTeamCover(teamId, selectedFile).then(
                     res => {
                        // Gérer la réponse si nécessaire
                     },
                  );
               }
               setForm(prevState => ({
                  ...prevState,
                  id: "",
                  nom: "",
                  username: "",
                  userCover: "",
                  phone: "",
                  description: "",
                  function: "",
                  localDate: "",
                  status: "",
               }));

               setTimeout(() => {
                  navigate("/admin/equipe");
               }, 2000);
            }
         });
      }

      setSaveInloading(false);
   }

   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Headerpanel
                  title={HeaderTitle}
                  searchValue={""}
                  setSearchValue={() => {}}
               />
               <Pane padding={16} flex={1} borderRadius={3}>
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <Pane display={"flex"} flexDirection={"column"}>
                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"row"}
                           justifyContent={"space-evenly"}>
                           <TextInputField
                              isInvalid={userForm.nom === ""}
                              value={userForm.nom}
                              name={"nom"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    nom: e.target.value,
                                 }));
                              }}
                              placeholder={"Entrez le nom de l'utilisateur"}
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le nom de l'utilisateur"
                           />
                           <TextInputField
                              isInvalid={userForm.username === ""}
                              value={userForm.username}
                              name={"nom"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    username: e.target.value,
                                 }));
                              }}
                              placeholder={"Entrez le username"}
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le username"
                           />

                           <TextInputField
                              isInvalid={userForm.function === ""}
                              value={userForm.function}
                              name={"function"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    function: e.target.value,
                                 }));
                              }}
                              placeholder={
                                 "Entrez la fonction de l'utilisateur"
                              }
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir la fonction de l'utilisateur"
                           />
                        </Pane>
                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"row"}
                           justifyContent={"space-evenly"}>
                           <TextInputField
                              isInvalid={userForm.phone === ""}
                              value={userForm.phone}
                              name={"telephone"}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    phone: e.target.value,
                                 }));
                              }}
                              placeholder={
                                 "Entrez le telephone de l'utilisateur"
                              }
                              flex={1}
                              marginX={5}
                              label="Veuillez saisir le telephone de l'utilisateur"
                           />
                        </Pane>

                        <Pane
                           display={"flex"}
                           flex={1}
                           flexDirection={"column"}>
                           <Label
                              htmlFor="textarea-2"
                              marginBottom={4}
                              display="block">
                              Description
                           </Label>
                           <Textarea
                              id="textarea-2"
                              placeholder=" Veuillez ajoutez la description ."
                              isInvalid={userForm.description === ""}
                              onChange={(e: any) => {
                                 setForm(prevState => ({
                                    ...prevState,
                                    description: e.target.value,
                                 }));
                              }}
                              value={userForm.description}
                           />
                        </Pane>
                        <Pane
                           display={"flex"}
                           flex={1}
                           padding={10}
                           flexDirection={"column"}
                           justifyContent={"space-evenly"}>
                           <FileUploaderSingleUpload ref={fileUploaderRef} />
                        </Pane>
                        <Pane marginTop={10}>
                           <Button
                              marginY={8}
                              marginRight={12}
                              iconAfter={Save}
                              isLoading={saveingLoading}
                              intent={"success"}>
                              Valider
                           </Button>
                        </Pane>
                     </Pane>
                  </form>
               </Pane>
            </dl>
         </div>
      </div>
   );
};
