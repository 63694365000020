import {translate} from "../../../i18n/translate";

const PresentationSection = () => {
   return (
      <>
         <section className="py-20">
            <div className="container flex items-center max-w-6xl mx-auto px-3 relative">
               <div className="flex-1 relative z-[2] mx-5 md:ml-16 md:mr-7 bg-[rgba(255,255,255,0.9)] rounded-lg p-8 shadow-lg lg:p-0 lg:ml-0 lg:mr-0 lg:bg-none lg:rounded-none lg:shadow-none">
                  <h2 className="text-center text-4xl text-gray-700 font-bold">
                     {translate("TR_12")}
                  </h2>
                  <p className="text-gray-500 text-justify mt-6 font-light text-lg leading-7">
                     {translate("TR_13")}
                  </p>
               </div>
               <div className="flex-1 mx-4 md:mx-0 md:ml-10 absolute -top-10 left-0 md:w-1/2 lg:block z-[1] lg:static">
                  <img src="home-1.jpg" className="w-full rounded-lg" alt="" />
               </div>
            </div>
         </section>
      </>
   );
};

export default PresentationSection;
