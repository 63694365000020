import {Heading, Pane} from "evergreen-ui";

export const Parametres = () => {
   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Pane display="flex" padding={16} borderRadius={3}>
                  <Pane flex={1} alignItems="center" display="flex">
                     <Heading size={600}>Parametre</Heading>
                  </Pane>
                  <Pane></Pane>
               </Pane>
               <Pane
                  display="flex"
                  flexDirection={"row"}
                  padding={16}
                  borderRadius={3}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Aliquid, blanditiis?
               </Pane>
            </dl>
         </div>
      </div>
   );
};
