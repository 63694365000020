import {
   CreditCardIcon,
   DevicePhoneMobileIcon,
   WalletIcon,
} from "@heroicons/react/20/solid";

import {translate} from "../../i18n/translate";

function DonsPage() {
   return (
      <div
         className="text-center bg-no-repeat bg-center bg-cover flex flex-col h-screen"
         style={{
            backgroundImage: `url(/images/dons-img.jpg)`,
            zIndex: "-2",
         }}>
         <div className="bg-black/20 flex items-center justify-center h-screen">
            <div className="max-w-2xl">
               <h1 className="text-4xl md:text-6xl text-white font-bold">
                  {translate("TR_38")}
               </h1>
               <p className="py-2 text-white text-xl">{translate("TR_101")}</p>
               <div className="py-4 px-8 md:px-0">
                  <a
                     className="mb-4 py-1 px-1 flex items-center content-center justify-center bg-gray-500 leading-tight shadow appearance-none"
                     href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=CWUJLUHQEXVXU&source=url&ssrt=1689235905292"
                     target="_blank"
                     rel="noreferrer">
                     <WalletIcon className="h-8 w-8 ml-4 text-white" />
                     <p className="text-2xl py-2 px-4 text-white">
                        {translate("TR_168")}
                     </p>
                     <CreditCardIcon className="h-8 w-8 mr-4 text-white" />
                  </a>
               </div>
               <div className="py-4 px-8 md:px-0">
                  <a
                     className="mb-4 py-1 px-1 flex items-center content-center justify-center bg-green-400 leading-tight shadow appearance-none"
                     target="_blank">
                     <DevicePhoneMobileIcon className="h-8 w-8 ml-4 text-white" />
                     <p className="text-2xl py-2 px-4 text-white">CinetPay</p>
                  </a>
               </div>
               <div className="py-4 px-8 md:px-0">
                  <a
                     className="mb-4 py-1 px-1 flex items-center content-center justify-center bg-violet-400 leading-tight shadow appearance-none"
                     target="_blank">
                     <CreditCardIcon className="h-8 w-8 ml-4 text-white" />
                     <p className="text-2xl py-2 px-4 text-white">Stripe</p>
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}

export default DonsPage;
