import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {Bars3Icon} from "@heroicons/react/20/solid";
import {routes} from "../router/routes";
import {NavLink} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

function classNames(...classes: any[]) {
   return classes.filter(Boolean).join(" ");
}

function DropdownMobile() {
   return (
      <Menu as="div" className="relative inline-block text-left">
         <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md  text-xs uppercase">
               <Bars3Icon
                  className="-mr-1 h-5 w-5 text-white"
                  aria-hidden="true"
               />
            </Menu.Button>
         </div>

         <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
               <div className="py-1">
                  {routes.map((route: any) => (
                     <Menu.Item key={uuidv4()}>
                        {({active}) => (
                           <NavLink
                              to={route.path}
                              className={classNames(
                                 active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                 "block px-4 py-2 text-sm",
                              )}>
                              {route.name}
                           </NavLink>
                        )}
                     </Menu.Item>
                  ))}
               </div>
            </Menu.Items>
         </Transition>
      </Menu>
   );
}

export default DropdownMobile;
