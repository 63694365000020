import {CornerDialog, Pane, toaster} from "evergreen-ui";
import type {HeaderTableType} from "./../../../types/header-table.type";
import {useEffect, useState} from "react";
import DatatableODTE from "./../../component/datatable";
import type {DatatableTeamsType} from "./../../../types/datatable-teams.type";
import {EquipeService} from "./../../../services/equipe.service";
import Headerpanel from "../../component/headerpanel";
import type {EquipeType} from "../../../types/equipe.type";
import {useNavigate} from "react-router-dom";

export const Equipe = () => {
   const HeaderLabel: HeaderTableType[] = [
      {key: "nom", label: "Nom", isFilter: true},
      {key: "username", label: "Username", isFilter: true},
      {key: "phone", label: "Tel", isFilter: true},
      {key: "description", label: "Description", isFilter: false},
      {key: "function", label: "Fonction", isFilter: true},
      {key: "isActive", label: "status", isFilter: false},
      {key: "action", label: "Action", isFilter: false},
   ];

   const title = "Menbre de l'équipe";
   const [searchValue, setSearchValue] = useState("");

   const [dateValue, setDateValue] = useState("");

   const [listeMenbreequipe, setListeMenbreequipe] =
      useState<DatatableTeamsType>({
         teamListe: [],
         totalRow: 0,
         currentPage: 0,
      });

   const [loading, setLoading] = useState<boolean>(true);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [tempeMenbre, setTempMenbre] = useState<EquipeType>({
      id: "",
      nom: "",
      username: "",
      userCover: "",
      phone: "",
      description: "",
      function: "",
      localDate: "",
      status: "",
   });
   const configAction: any = {
      edit: {
         state: true,
         url: `manage/`,
      },
      delete: {state: true, url: ""},
   };
   const [deleteModal, setDeleteModal] = useState<boolean>(false);
   const navigate = useNavigate();
   useEffect(() => {
      getListeUtilisateur();
      setLoading(false);
   }, [listeMenbreequipe.currentPage]);

   const getListeUtilisateur = () => {
      setLoading(true);
      EquipeService.getListeEquipe({
         pageNo: listeMenbreequipe.currentPage,
         pageSize: 10,
         sortBy: "",
         sortDir: searchValue,
      })
         .then(data => {
            setListeMenbreequipe(prevState => ({
               ...prevState,
               teamListe: data.teamListe,
               currentPage: data.currentPage,
               totalRow: data.totalRow,
            }));
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };

   function handleDeleteUtilisateur() {
      setDeleteModal(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const equipeId = tempeMenbre.id.toString();
      if (tempeMenbre.id !== "0") {
         void EquipeService.deleteEquipe(equipeId)
            .then(res => {
               if (res.success) {
                  toaster.success("OTDE NOTIFICATION", {
                     description: "Félicitation, le membre a été supprimé",
                  });
                  // Rafraîchir la liste des membres après la suppression
                  getListeUtilisateur();
               }
            })
            .catch(() => {
               // Gérer les erreurs de suppression ici
               toaster.danger("OTDE NOTIFICATION", {
                  description:
                     "Une erreur s'est produite lors de la suppression du membre.",
               });
            })
            .finally(() => {
               setDeleteModal(false);
            });
      }
   }

   const handleBtnSearch = () => {};

   const handleBtnExport = () => {};

   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Headerpanel
                  title={title}
                  searchValue={searchValue}
                  dateValue={dateValue}
                  handleButtonExport={handleBtnExport}
                  handleButtonSearch={handleBtnSearch}
                  handleDateChanged={setDateValue}
                  showSeachValue={true}
                  showdatechoosed={true}
                  showexportPdf={true}
                  showSearchBtn={true}
                  showAddEntity={true}
                  handleAdd={() => {
                     navigate("manage");
                  }}
                  setSearchValue={setSearchValue}
               />
               <Pane
                  display="flex"
                  flexDirection={"row"}
                  padding={16}
                  borderRadius={3}>
                  <DatatableODTE
                     filterValue={searchValue}
                     tableHeaderlabel={HeaderLabel}
                     tableData={listeMenbreequipe.teamListe}
                     totalRow={listeMenbreequipe.totalRow}
                     loading={loading}
                     handleCurrenyPage={setListeMenbreequipe}
                     configAction={configAction}
                     handleDelete={menbre => {
                        setTempMenbre(menbre);
                        setDeleteModal(true);
                     }}
                  />
                  <CornerDialog
                     title="Gestion de l'application"
                     isShown={deleteModal}
                     confirmLabel={"Supprimer"}
                     cancelLabel={"Annuler "}
                     onConfirm={handleDeleteUtilisateur}
                     onCloseComplete={() => {
                        setDeleteModal(false);
                     }}>
                     Voulez-vous supprimer cette ligne ?
                  </CornerDialog>
               </Pane>
            </dl>
         </div>
      </div>
   );
};
