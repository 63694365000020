import {Dropdown} from "./Dropdown";
import {translate} from "../i18n/translate";

function LanguageDropdown() {
   return (
      <Dropdown title={translate("TR_156")?.toUpperCase()}>
         <a
            href="#"
            className="text-gray-700 block px-4 py-2 text-sm"
            role="menuitem"
            tabIndex={-1}
            onClick={() => {
               localStorage.setItem("lang", "fr");
               window.location.reload();
            }}>
            <span className="fi fi-gr"></span> {translate("TR_0")}
         </a>
         <a
            href="#"
            className="text-gray-700 block px-4 py-2 text-sm"
            role="menuitem"
            tabIndex={-1}
            onClick={() => {
               localStorage.setItem("lang", "en");
               window.location.reload();
            }}>
            <span className="fi fi-gr"></span> {translate("TR_178")}
         </a>
      </Dropdown>
   );
}

export default LanguageDropdown;
