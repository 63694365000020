import {StatistquesMockCordonnes} from "./../mocks/statistques.mock";
import {DashboadDataStats} from "./../mocks/DashboadDataStats";
import {DonMockAll, DonMockPaypal, DonMockStripe} from "./../mocks/don.mock";
import type {StatsDashboadCordonneType} from "../types/stats-dashboad-cordonne.type";
import type {LastThirdtyCashDon} from "../types/last-thirdty-cash-don";
import {type PaiementType} from "../types/paiement.type";

export const StatistiqueService = {
   async getCordonneeStatistiques(
      param: string,
   ): Promise<StatsDashboadCordonneType[]> {
      return await new Promise((resolve: any) => {
         setTimeout(() => resolve(StatistquesMockCordonnes), 1000);
      });
   },
   async getLastThirtyDonated(param: any): Promise<LastThirdtyCashDon> {
      return await new Promise((resolve: any) => {
         setTimeout(() => resolve(DashboadDataStats), 1000);
      });
   },

   async getLastDonatedbyId(id: number): Promise<PaiementType[]> {
      const data = [DonMockAll, DonMockStripe, DonMockPaypal];
      return await new Promise((resolve: any) => {
         setTimeout(() => resolve(data[id]), 1000);
      });
   },
};
