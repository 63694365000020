import {
   MapPinIcon,
   PhoneIcon,
   ShoppingBagIcon,
} from "@heroicons/react/20/solid";
import InformationContact from "./InformationContact";
import FormContact from "./FormContact";
import {Banner} from "../../components/Banner";
import {translate} from "../../i18n/translate";

function ContactPage() {
   return (
      <div>
         <Banner
            url="/images/contact-banner.jpg"
            title={translate("TR_118")}
            subTitle={translate("TR_119")}
         />
         <section className="flex flex-col lg:flex-row text-gray-600">
            <div className="p-4 lg:p-12 lg:w-1/2 px-8 lg:px-32">
               <FormContact />
            </div>
            <div className="p-4 lg:p-12 lg:w-1/2 text-center md:text-left px-8 lg:px-32">
               <div className="py-6">
                  <InformationContact
                     title={translate("TR_132")}
                     details1={translate("TR_133")}
                     details2={translate("TR_134")}
                     details3={translate("TR_135")}
                     Icon={MapPinIcon}
                  />
               </div>
               <div className="py-6">
                  <InformationContact
                     title={translate("TR_129")}
                     details1={translate("TR_136")}
                     details2={"+33 000 000 000"}
                     details3={translate("TR_137")}
                     Icon={PhoneIcon}
                  />
               </div>
               <div className="py-6">
                  <InformationContact
                     title={translate("TR_138")}
                     details1={translate("TR_12")}
                     details2={translate("TR_139")}
                     details3={translate("TR_140")}
                     Icon={ShoppingBagIcon}
                  />
               </div>
            </div>
         </section>
      </div>
   );
}

export default ContactPage;
