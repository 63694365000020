import type {EquipeType} from "../types/equipe.type";
import URL_MICRO_SERVICE from "./URL";
import AxionService from "./axion.service";
import {type ResponseEquipeType} from "../types/response-equipe.type";
import {type PagginationQueryRequestType} from "../types/paggination-query-request.type";
import {type DatatableResponseType} from "../types/datatable-response.type";
import {type ResponseEntityType} from "../types/response-entity.type";
import {type DatatableTeamsType} from "../types/datatable-teams.type";

/**
 * @Constante du micro service
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const URL_MICRO_COMMAND = URL_MICRO_SERVICE.UTILISATEUR_COMMANDE_SERVICE;
const URL_MICRO_QUERY = URL_MICRO_SERVICE.UTILISATEUR_QUERY_SERVICE;
/*
 * @Instance axios
 * */
const axiosService = new AxionService();

export const EquipeService = {
   // temporairement ...
   async getEquipe(): Promise<EquipeType[]> {
      return await new Promise((resolve: any) => {
         setTimeout(() => resolve([]), 1000);
      });
   },
   async getequipesListeForLanding(): Promise<ResponseEquipeType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get("teams")
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   async getListeEquipe(
      pagginationQueryRequestType: PagginationQueryRequestType,
   ): Promise<DatatableTeamsType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get<DatatableResponseType<EquipeType>>(
               `teams/pageTeams?pageNo=${pagginationQueryRequestType.pageNo}&pageSize=${pagginationQueryRequestType.pageSize}&sortBy=${pagginationQueryRequestType.sortBy}&sortDir=${pagginationQueryRequestType.sortDir}`,
            )
            .then(res => {
               const returnArrayDatatable: DatatableTeamsType = {
                  teamListe: res.data.content,
                  currentPage: res.data.pageNo,
                  totalRow: res.data.totalElements,
               };
               resolve(returnArrayDatatable);
            })
            .catch(error => reject(error));
      });
   },

   async postCreateTeam(team: EquipeType): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("team/create", team)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   async updateTeams(
      team: EquipeType,
      id: string,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .put<ResponseEntityType<string>, EquipeType>(
               `team/update/${id}`,
               team,
            )
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   async getTeams(id: string): Promise<ResponseEntityType<EquipeType>> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get(`teams/${id}`)
            .then(res => {
               return resolve(res);
            })
            .catch(error => reject(error));
      });
   },

   async getImageTeam(id: string | undefined) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${URL_MICRO_QUERY}/teams/image/${id}`;
   },

   async uploadTeamCover(
      id: string,
      file: File,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      const formData = new FormData();
      formData.append("file", file);
      try {
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         // @ts-expect-error
         const res = await axiosService.post(`team/${id}`, formData, {
            headers: {
               "Content-Type": "multipart/form-data",
            },
         });
         return res.data;
      } catch (error) {
         console.error("Erreur lors de l'envoi du fichier", error);
         throw error;
      }
   },

   deleteEquipe: async (id: string): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .delete(`team/${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
};
