import Datepicker from "react-tailwindcss-datepicker";
import "./index.css";
import PropTypes from "prop-types";

interface DatepickerOTProps {
   callBack: (arg: any) => void;
   value: any;
}

const DatepickerOT: React.FC<DatepickerOTProps> = ({callBack, value}) => {
   return (
      <Datepicker
         i18n={"fr"}
         inputClassName={"date-picker-otde"}
         onChange={callBack}
         value={value}
         primaryColor={"green"}
      />
   );
};
DatepickerOT.propTypes = {
   callBack: PropTypes.func.isRequired,
   value: PropTypes.any.isRequired,
};

export default DatepickerOT;
