import {useState} from "react";
import InputIcon from "../../components/InputIcon";
import logo from "../../images/logo-map-charity.png";
import {EnvelopeIcon, LockClosedIcon} from "@heroicons/react/20/solid";
import {translate} from "../../i18n/translate";
import ErrorComponent from "../../components/ErrorComponent";
import {UtilisateurService} from "../../services/utilisateur.service";
import {toaster} from "evergreen-ui";

function OubliPage() {
   const [errorMessage, setErrorMessage] = useState("");
   const [email, setEmail] = useState("");
   const [code, setCode] = useState("");
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [step, setStep] = useState(1);
   const [showError, setShowError] = useState(false);
   const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
   let resetCode: any = "";
   const isFormValid = () => {
      if (step === 1) {
         if (email === "") {
            setErrorMessage(translate("TR_120"));
            return false;
         } else if (!validEmail.test(email)) {
            setErrorMessage(translate("TR_122"));
            return false;
         } else {
            setErrorMessage("");
            return true;
         }
      } else if (step === 2) {
         if (code.length < 6) {
            setErrorMessage(translate("TR_176"));
            return false;
         }
      } else {
         if (
            password === "" ||
            confirmPassword === "" ||
            password !== confirmPassword
         ) {
            setErrorMessage(translate("TR_177"));
            return false;
         }
      }
      return true;
   };
   const submitLogin = (event: any) => {
      event.preventDefault();
      if (isFormValid()) {
         setShowError(false);
         step === 1 ? setStep(2) : step === 2 ? setStep(3) : <></>;
         if (step === 1) {
            void UtilisateurService.sendEmail(email)
               .then((res: any) => {
                  resetCode = res.data;
                  // Vérifiez si le code de réinitialisation est présent dans la réponse
                  if (
                     resetCode !== null ||
                     resetCode !== undefined ||
                     resetCode !== ""
                  ) {
                     toaster.success("OTDE NOTIFICATION", {
                        description: translate("TR_218"),
                     });
                  } else {
                     // Gérez le cas où le code de réinitialisation n'est pas présent dans la réponse
                     toaster.danger("OTDE ERROR", {
                        description: "Erreur lors de l'envoie du code.",
                     });
                  }
               })
               // eslint-disable-next-line n/handle-callback-err
               .catch((error: any) => {
                  // Gérez les erreurs, par exemple, en affichant un message d'erreur à l'utilisateur
                  toaster.danger("OTDE ERROR", {
                     description:
                        "Erreur lors de l'envoi de l'e-mail de réinitialisation.",
                  });
               });
         } else if (step === 2) {
            void UtilisateurService.verifyResetCode({email, code}).then(
               (res: any) => {
                  resetCode = res.success;
                  if (resetCode === true) {
                     toaster.success("OTDE NOTIFICATION", {
                        description: translate("TR_219"),
                     });
                  } else {
                     setStep(2);
                     toaster.danger("OTDE ERROR", {
                        description: "Veiilez entrer le bon code svp!.",
                     });
                  }
               },
            );
         } else if (step === 3) {
            void UtilisateurService.updatePassword({
               email,
               password,
            }).then((res: any) => {
               if (res.success === true) {
                  toaster.success("OTDE NOTIFICATION", {
                     description: translate("TR_219"),
                  });
                  const port = window.location.port;
                  window.location.href = `//${window.location.hostname}${
                     port !== "" ? `:${port}` : ""
                  }/connexion`;
               } else {
                  toaster.danger("OTDE ERROR", {
                     description:
                        "Erreur lors de l'enregistrement l'enregistrement!.",
                  });
               }
            });
         }
      } else {
         setShowError(true);
      }
   };
   return (
      <div
         className="text-center bg-no-repeat bg-center bg-cover flex flex-col h-screen bg-green-500/50"
         style={{
            backgroundImage: `url(/images/image-connexion.jpg)`,
            zIndex: "-2",
         }}>
         <div className="bg-gradient-to-br from-green-950/80 to-green-500/70 flex items-center justify-center h-screen">
            <div className="text-center py-20">
               <img src={logo} className="w-16 h-16 mx-auto" alt="" />
               <p className="text-white font-bold py-4 text-lg max-w-lg mx-auto">
                  {step === 1
                     ? translate("TR_170")
                     : step === 2
                     ? translate("TR_172")
                     : translate("TR_173")}
               </p>
               {showError ? (
                  <div className="py-4">
                     <ErrorComponent errorMessage={errorMessage} />
                  </div>
               ) : null}
               <form className="px-8 pt-6 pb-4 mb-2" onSubmit={submitLogin}>
                  {step === 1 ? (
                     <InputIcon
                        inputValue={email}
                        setInputValue={setEmail}
                        code="TR_142"
                        type="text"
                        id="email"
                        Icon={EnvelopeIcon}
                        obligatory={true}
                        maxLength={100}
                     />
                  ) : step === 2 ? (
                     <InputIcon
                        inputValue={code}
                        setInputValue={setCode}
                        code="TR_171"
                        type="text"
                        id="code"
                        Icon={LockClosedIcon}
                        obligatory={true}
                        maxLength={6}
                     />
                  ) : (
                     <>
                        <InputIcon
                           inputValue={password}
                           setInputValue={setPassword}
                           code="TR_87"
                           type="password"
                           id="password"
                           Icon={LockClosedIcon}
                           obligatory={true}
                           maxLength={100}
                        />
                        <InputIcon
                           inputValue={confirmPassword}
                           setInputValue={setConfirmPassword}
                           code="TR_174"
                           type="password"
                           id="confirmPassword"
                           Icon={LockClosedIcon}
                           obligatory={true}
                           maxLength={100}
                        />
                     </>
                  )}
                  {step === 2 ? (
                     <div className="flex items-center">
                        <a className="inline-block align-baseline font-bold text-sm text-white mx-auto">
                           {translate("TR_175")}
                        </a>
                     </div>
                  ) : (
                     <></>
                  )}

                  <div className="flex pt-4 items-center">
                     <button
                        type="submit"
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-24 md:px-32 rounded-full mx-auto focus:outline-none focus:shadow-outline">
                        {translate("TR_169")}
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}

export default OubliPage;
