import {ActionsHumanitairesSection} from "./sections/ActionsHumanitairesSection";
import {AppHero} from "../../components/AppHero";
import PresentationSection from "./sections/PresentationSection";
import MissionsValeursSection from "./sections/MissionsValeursSection";

const Accueil = () => {
   return (
      <>
         <AppHero />
         <PresentationSection />
         <MissionsValeursSection />
         <ActionsHumanitairesSection />
      </>
   );
};

export default Accueil;
