import {BankAccountIcon, Heading, Icon, Pane, Text} from "evergreen-ui";
import type React from "react";

interface ResumeBoxProps {
   amount?: string;
   labels?: string;
   color?: string;
   iconColor?: string;
}

export const ResumeBox: React.FC<ResumeBoxProps> = ({
   amount,
   labels,
   color,
   iconColor,
}) => {
   return (
      <Pane
         elevation={4}
         float="left"
         backgroundColor={color}
         width={230}
         height={120}
         margin={10}
         border={true}
         display="flex"
         borderRadius={10}
         justifyContent="left"
         alignItems="left"
         paddingLeft={10}
         paddingTop={20}
         flexDirection="column">
         <Icon
            icon={BankAccountIcon}
            size={30}
            color={iconColor}
            marginBottom={2}
         />
         <Heading size={500} color={iconColor} marginBottom={2}>
            {amount}
         </Heading>
         <Text size={400} color={iconColor} marginBottom={2}>
            {labels}
         </Text>
      </Pane>
   );
};
