import {useState} from "react";
import ErrorComponent from "../../components/ErrorComponent";
import {translate} from "../../i18n/translate";
import {ContactService} from "../../services/contact.service";
import {toaster} from "evergreen-ui";

function FormContact() {
   const [loading, setLoading] = useState(false);
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [phoneNumber, setPhoneNumber] = useState("");
   const [adress, setAdress] = useState("");
   const [message, setMessage] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [showError, setShowError] = useState(false);
   const validPhoneNumber =
      /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
   const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
   const isFormValid = () => {
      if (
         message === "" ||
         name === "" ||
         email === "" ||
         phoneNumber === "" ||
         adress === ""
      ) {
         setErrorMessage(translate("TR_120"));
         return false;
      } else if (!validEmail.test(email)) {
         if (!validPhoneNumber.test(phoneNumber)) {
            setErrorMessage(translate("TR_121"));
            return false;
         } else {
            setErrorMessage(translate("TR_122"));
            return false;
         }
      } else if (!validPhoneNumber.test(phoneNumber)) {
         setErrorMessage(translate("TR_123"));
         return false;
      } else {
         setErrorMessage("");
         return true;
      }
   };
   const submitMessage = (event: any) => {
      setLoading(true);
      event.preventDefault();
      if (isFormValid()) {
         setShowError(false);
         void ContactService.postContact({
            nom: name,
            message,
            telephone: phoneNumber,
            adresse: adress,
            email,
         }).then(res => {
            if (res.success) {
               toaster.success("OTDE NOTIFICATION", {
                  description:
                     "Votre message a été envoyé , merci et à bientôt",
               });

               setName("");
               setEmail("");
               setPhoneNumber("");
               setAdress("");
               setMessage("");
            }
            setLoading(false);
         });
         // Send to db
      } else {
         setShowError(true);
         // Showing error
      }
   };
   return (
      <>
         <h1 className="font-bold text-4xl">{translate("TR_124")}</h1>
         <p className="py-8">
            {translate("TR_125")}.{translate("TR_126")}
         </p>
         {showError ? <ErrorComponent errorMessage={errorMessage} /> : null}

         <form className="px-2 lg:px-8" onSubmit={submitMessage}>
            <div className="py-2">
               <label className="block text-sm font-bold mb-2">
                  {translate("TR_127")}
               </label>
               <input
                  value={name}
                  onChange={e => {
                     setName(e.target.value);
                  }}
                  className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder={translate("TR_127").concat("...")}
               />
            </div>
            <div className="py-2">
               <label className="block text-sm font-bold mb-2">
                  {translate("TR_128")}
               </label>
               <input
                  value={email}
                  onChange={e => {
                     setEmail(e.target.value);
                  }}
                  className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder={translate("TR_128").concat("...")}
               />
            </div>
            <div className="py-2">
               <label className="block text-sm font-bold mb-2">
                  {translate("TR_129")}
               </label>
               <input
                  value={phoneNumber}
                  onChange={e => {
                     setPhoneNumber(e.target.value);
                  }}
                  className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder={translate("TR_129").concat("...")}
               />
            </div>
            <div className="py-2">
               <label className="block text-sm font-bold mb-2">
                  {translate("TR_130")}
               </label>
               <input
                  value={adress}
                  onChange={e => {
                     setAdress(e.target.value);
                  }}
                  className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder={translate("TR_130").concat("...")}
               />
            </div>
            <div className="py-2">
               <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {translate("TR_131")}
               </label>
               <textarea
                  value={message}
                  onChange={e => {
                     setMessage(e.target.value);
                  }}
                  rows={4}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={translate("TR_131").concat("...")}
               />
            </div>
            <div className="py-2 text-center">
               <button
                  disabled={loading}
                  className="shadow bg-green-500 hover:bg-green-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="submit">
                  {translate("TR_132")}
               </button>
            </div>
         </form>
      </>
   );
}

export default FormContact;
