import {Don} from "../admin/page/don/";
import {Equipe} from "../admin/page/equipe/";
import {ManageEquipe} from "../admin/page/equipe/manage.equipe";
import {Courriels} from "../admin/page/courriels";
import {Projets} from "../admin/page/projets";
import {Parametres} from "../admin/page/parametres";
import {Utilisateurs} from "../admin/page/utilisateurs";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SavingsIcon from "@mui/icons-material/Savings";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TuneIcon from "@mui/icons-material/Tune";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {AdminIndex} from "../admin";
import {ManageUtilisateur} from "../admin/page/utilisateurs/manage.utilisateur";
import {ManageProjet} from "../admin/page/projets/manage.projet";
import {LogOutIcon} from "evergreen-ui";
import {Deconnexion} from "../admin/page/deconnexion";

export const routesAdmin: any[] = [
   {
      path: "/admin",
      component: <AdminIndex />,
      title: "Tableau de bord",
      src: DashboardIcon,
      gap: false,
      children: false,
   },
   {
      path: "/admin/don",
      component: <Don />,
      title: "Don",
      src: SavingsIcon,
      gap: false,
      children: false,
   },
   {
      path: "/admin/equipe",
      component: <Equipe />,
      title: "Equipe ",
      src: PeopleAltIcon,
      gap: false,
      children: false,
   },
   {
      path: "/admin/equipe/manage",
      component: <ManageEquipe />,
      title: "Equipe ",
      src: PeopleAltIcon,
      gap: false,
      children: true,
   },
   {
      path: "/admin/equipe/manage/:id",
      component: <ManageEquipe />,
      title: "Equipe ",
      src: PeopleAltIcon,
      gap: false,
      children: true,
   },
   {
      path: "/admin/courriels",
      component: <Courriels />,
      title: "Courriels",
      src: MarkEmailReadIcon,
      gap: false,
      children: false,
   },
   {
      path: "/admin/projets",
      component: <Projets />,
      title: "Projets",
      src: FolderCopyIcon,
      gap: false,
      children: false,
   },
   {
      path: "/admin/projets/manage",
      component: <ManageProjet />,
      title: "Projets",
      src: FolderCopyIcon,
      gap: false,
      children: true,
   },
   {
      path: "/admin/projets/manage/:id",
      component: <ManageProjet />,
      title: "Projets",
      src: FolderCopyIcon,
      gap: false,
      children: true,
   },
   {
      path: "/admin/utilisateurs",
      component: <Utilisateurs />,
      title: "Utilisateurs",
      src: AccountCircleIcon,
      gap: false,
      children: false,
   },
   {
      path: "/admin/utilisateurs/manage",
      component: <ManageUtilisateur />,
      title: "Gestion utilisateur",
      src: null,
      gap: false,
      children: true,
   },
   {
      path: "/admin/utilisateurs/manage/:id",
      component: <ManageUtilisateur />,
      title: "Modification utilisateur",
      src: null,
      gap: false,
      children: true,
   },
   {
      path: "/admin/parametres",
      component: <Parametres />,
      title: "Parametres",
      src: TuneIcon,
      gap: true,
      children: false,
   },
   {
      path: "/admin/deconnexion",
      component: <Deconnexion />,
      title: "Deconnexion",
      src: LogOutIcon,
      gap: true,
      children: false,
   },
];
