import {ResumeBox} from "../card/box/ResumBox";
import {TotalDonBox} from "../card/box/TotalDonBox";
import ContainerOTDE from "../container/ContainerOTDE";
import {useEffect, useState} from "react";
import {StatistiqueService} from "../../../services/statistique.service";
import {type LastThirdtyCashDon} from "../../../types/last-thirdty-cash-don";

export const StatsCashDonLast = () => {
   const [loading, setLoading] = useState<boolean>(true);
   const [statsData, setStatsData] = useState<LastThirdtyCashDon>({
      receveid: {},
      total: {},
      donated: {},
   });

   useEffect(() => {
      getLastThirtyDon();
   }, []);

   const getLastThirtyDon = () => {
      setLoading(true);
      StatistiqueService.getLastThirtyDonated({})
         .then(data => {
            setStatsData(data);
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };
   return (
      <ContainerOTDE loading={loading} line={2}>
         <ResumeBox
            color={"#1DBE00"}
            amount={statsData.receveid?.amount}
            iconColor={"white"}
            labels={statsData.receveid?.label}
         />
         <ResumeBox
            color={"#0B0B0B"}
            amount={statsData.donated?.amount}
            iconColor={"white"}
            labels={statsData.donated?.label}
         />
         <TotalDonBox
            color={"#e3e3e3e"}
            amount={statsData.total?.amount}
            year={statsData.total?.year}
            labels={statsData.total?.label}
         />
      </ContainerOTDE>
   );
};
