import {translate} from "../i18n/translate";

export function AppHero() {
   return (
      <div className="h-screen flex items-center app-hero">
         <section className="w-full bg-cover bg-center py-32">
            <div className="container mx-auto text-center text-white">
               <div className="flex justify-center items-center">
                  <img
                     src="/images/logo-map-charity.png"
                     alt="logo map charity"
                     className="logo-map"
                  />
               </div>
               <p className="text-4xl mb-6 uppercase">{translate("TR_101")}</p>
               <p className="text-xl mb-12">{translate("TR_102")}!</p>
            </div>
         </section>
      </div>
   );
}
