import {Route, Routes} from "react-router-dom";
import {lazy, Suspense, useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";
import Loader from "./common/Loader";
import {AdminIndex} from "./admin/index";
import {routesAdmin} from "./router/routeAdmin";
import {routes} from "./router/routes";
import Accueil from "./pages/accueil/Accueil";
import {LandingPageLayout} from "./layout/LandingLayout";

const AdminLayout = lazy(async () => {
   return await import("./layout/AdminLayout");
});

function OTDELayout() {
   const [loading, setLoading] = useState<boolean>(true);
   const [user, setUseractive] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setLoading(false);
      }, 1000);
      const storedData = window.sessionStorage.getItem("OTDE");
      const accessToken =
         storedData !== null ? JSON.parse(storedData).accessToken : null;
      if (
         window.sessionStorage.getItem("OTDE") !== null &&
         accessToken !== undefined
      ) {
         setUseractive(true);
      } else {
         // TODO should to remove in prod mode
         // setUseractive(true);
      }
   }, []);
   const getLandingPageLayout = () => {
      return loading ? (
         <Loader />
      ) : (
         <>
            <Toaster
               position="top-right"
               reverseOrder={false}
               containerClassName="overflow-auto"
            />
            <Routes>
               <Route element={<LandingPageLayout />}>
                  <Route index element={<Accueil />} />
                  {routes.map((routes: any, index: any) => {
                     return (
                        <Route
                           key={index}
                           path={routes.path}
                           element={
                              <Suspense fallback={<Loader />}>
                                 {" "}
                                 {routes.element}{" "}
                              </Suspense>
                           }
                        />
                     );
                  })}
               </Route>
            </Routes>
         </>
      );
   };

   const getAdminLayout = () => {
      return loading ? (
         <Loader />
      ) : (
         <>
            <Toaster
               position="top-right"
               reverseOrder={false}
               containerClassName="overflow-auto"
            />
            <Routes>
               <Route element={<AdminLayout />}>
                  <Route index element={<AdminIndex />} />
                  {routesAdmin.map((routes: any, index: any) => {
                     return (
                        <Route
                           key={index}
                           path={routes.path}
                           element={
                              <Suspense fallback={<Loader />}>
                                 {" "}
                                 {routes.component}{" "}
                              </Suspense>
                           }
                        />
                     );
                  })}
               </Route>
            </Routes>
         </>
      );
   };
   return user ? getAdminLayout() : getLandingPageLayout();
}

export default OTDELayout;
