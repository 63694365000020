import {NavLink, useNavigate} from "react-router-dom";
import logo from "../../images/logo-map-charity.png";
import ErrorComponent from "../../components/ErrorComponent";
import {useState} from "react";
import {translate} from "../../i18n/translate";
import InputIcon from "../../components/InputIcon";
import {EnvelopeIcon, LockClosedIcon} from "@heroicons/react/20/solid";
import {UtilisateurService} from "../../services/utilisateur.service";
import {toaster} from "evergreen-ui";

function ConnexionPage() {
   const [errorMessage, setErrorMessage] = useState("");
   const [showError, setShowError] = useState(false);
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const navigate = useNavigate();
   const isFormValid = () => {
      if (password === "" || email === "") {
         setErrorMessage(translate("TR_120"));
         return false;
      } else {
         setErrorMessage("");
         return true;
      }
   };

   const submitLogin = (event: any) => {
      event.preventDefault();
      if (isFormValid()) {
         setShowError(false);
         void UtilisateurService.login({
            username: email,
            password,
         })
            .then((res: any) => {
               window.sessionStorage.setItem("OTDE", JSON.stringify(res.data));
               if (res.data.roles.includes("ADMIN") === true) {
                  toaster.success("OTDE NOTIFICATION", {
                     description: "Vous êtes connecté!",
                  });
                  navigate("/admin");
                  window.location.reload();
               }
            })
            .catch((res: any) => {
               toaster.danger("OTDE NOTIFICATION", {
                  description: "Email ou Mot de passe incorrect!",
               });
            });
      } else {
         setShowError(true);
         // Showing error
      }
   };
   return (
      <div
         className="text-center bg-no-repeat bg-center bg-cover flex flex-col h-screen bg-green-500/50"
         style={{
            backgroundImage: `url(/images/image-connexion.jpg)`,
            zIndex: "-2",
         }}>
         <div className="bg-gradient-to-br from-green-950/80 to-green-500/70 flex items-center justify-center h-screen">
            <div className="text-center py-20">
               <img src={logo} className="w-16 h-16 mx-auto" alt="" />
               {showError ? (
                  <div className="py-4">
                     <ErrorComponent errorMessage={errorMessage} />
                  </div>
               ) : null}

               <form className="px-8 pt-6 pb-8 mb-4" onSubmit={submitLogin}>
                  <InputIcon
                     inputValue={email}
                     setInputValue={setEmail}
                     code="TR_142"
                     type="text"
                     id="email"
                     Icon={EnvelopeIcon}
                     obligatory={false}
                     maxLength={100}
                  />
                  <InputIcon
                     inputValue={password}
                     setInputValue={setPassword}
                     code="TR_141"
                     type="password"
                     id="password"
                     Icon={LockClosedIcon}
                     obligatory={false}
                     maxLength={100}
                  />
                  <div className="flex pt-8 items-center">
                     <button
                        type="submit"
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-24 md:px-32 rounded-full mx-auto focus:outline-none focus:shadow-outline">
                        {translate("TR_7")}
                     </button>
                  </div>
                  <div className="flex pt-2">
                     <NavLink
                        className="inline-block align-baseline font-bold text-sm text-white mr-auto"
                        to="/inscription">
                        {translate("TR_143")}
                     </NavLink>
                     <NavLink
                        className="inline-block align-baseline font-bold text-sm text-white ml-auto"
                        to="/forgot-password">
                        {translate("TR_144")}
                     </NavLink>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}

export default ConnexionPage;
