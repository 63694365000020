import {type LastThirdtyCashDon} from "../types/last-thirdty-cash-don";

export const DashboadDataStats: LastThirdtyCashDon = {
   receveid: {
      amount: "1500000 CFA",
      label: "Les 30 derniers jours",
   },
   donated: {
      amount: "3,600 CFA",
      label: "Les 30 derniers jours",
   },
   total: {
      amount: "3,600 CFA",
      label: "Les 30 derniers jours",
      year: "2023",
   },
};
