import {Heading, Pane, toaster} from "evergreen-ui";
import {UtilisateurService} from "../../../services/utilisateur.service";
import {useEffect} from "react";

export const Deconnexion = () => {
   useEffect(() => {
      deconnexion();
   }, []);
   const deconnexion = (event: any = null) => {
      void UtilisateurService.logout().then(res => {
         if (res.success) {
            toaster.success("OTDE NOTIFICATION", {
               description: "Vous êtes déconnecté",
            });
            window.sessionStorage.clear();
            const port = window.location.port;
            window.location.href = `//${window.location.hostname}${
               port !== "" ? `:${port}` : ""
            }/`;
         }
      });
   };
   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Pane display="flex" padding={16} borderRadius={3}>
                  <Pane flex={1} alignItems="center" display="flex">
                     <Heading size={600}>Déconnexion</Heading>
                  </Pane>
                  <Pane></Pane>
               </Pane>
               <Pane
                  display="flex"
                  flexDirection={"row"}
                  padding={16}
                  borderRadius={3}></Pane>
            </dl>
         </div>
      </div>
   );
};
