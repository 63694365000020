import React, {useState} from "react";
import ProjectModal from "./ProjectModal";
import {translate} from "../i18n/translate";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {MenuItem, TextField, Typography} from "@mui/material";
import {Button, IconButton, toaster} from "evergreen-ui";
import Select from "@mui/material/Select";
import {DonService} from "../services/don.service";
import CloseIcon from "@mui/icons-material/Close";

interface ProjectProps {
   srcImg: string;
   projectTitle: string;
   projectDescription: string;
   projectObjective: string;
   index: string;
   setSelectedProject: any;
   selectedProject: any;
}

const style = {
   position: "absolute" as "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   pt: 2,
   px: 4,
   pb: 3,
};

function ProjectComponent({
   srcImg,
   projectTitle,
   projectDescription,
   projectObjective,
   index,
   setSelectedProject,
   selectedProject,
}: ProjectProps) {
   const [loading, setLoading] = useState(false);
   const [price, setPrice] = useState(1);
   const [currency, setCurrency] = useState<string>("");
   const [isModalOpen, setIsModalOpen] = useState(false);
   const openModal = () => {
      setIsModalOpen(true);
   };
   const closeModal = () => {
      setIsModalOpen(false);
   };
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => {
      setOpen(true);
      setSelectedProject(index);
   };
   const handleClose = () => {
      setOpen(false);
   };

   const handleDonationAmout = (value: number) => {
      if (value < 1) {
         setPrice(1);
      } else {
         setPrice(value);
      }
   };

   const handleSubmit = (event: any) => {
      setLoading(true);
      event.preventDefault();
      void DonService.addDon({
         id: "",
         localDate: Date.now().toString(),
         userData: {
            id: undefined,
            nom: "",
            prenom: "",
            username: "",
            email: "",
            telephone: "",
            password: "",
            adresse: "",
            roles: [""],
         },
         projetData: {
            titre: projectTitle,
            description: projectDescription,
            objectif: projectObjective,
            cover: "",
            id: index,
         },
         orderData: {
            id: "",
            price,
            currency,
            method: "paypal",
            intent: "sale",
         },
         status: "",
      }).then(res => {
         if (res.success) {
            window.location.href = res.data;
            setTimeout(() => {
               setPrice(1);
               setCurrency("EUR");
            }, 2000);
         } else {
            toaster.danger("OTDE NOTIFICATION", {
               description:
                  "Une erreur s'est produite lors de la validation, veillez réessayer svp!",
            });
         }
         console.log("testttttttttttttt");
      });
   };

   return (
      <div className="p-4 sm:w-1/2 lg:w-1/3">
         <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img
               src={srcImg}
               alt="image"
               className="lg:h-72 md:h-48 w-full object-cover object-center cursor-pointer"
               onClick={openModal}
            />
            <div className=" p-6 ease-in">
               <h2 className="text-base font-medium text-red-600 mb-1 flex items-center">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     className="w-6 h-6 mr-3 "
                     viewBox="0 0 24 24">
                     <g transform="translate(0 24) scale(1 -1)">
                        <path
                           fill="red"
                           d="M21.909 10.928H24v2.02h-2.091zm-3.635 0h2.091v2.02h-2.091zm-6.022.001l-.657 2.215l-3.772-8.343l-1.954 6.17H0v2.02h7.346l.81-2.551l3.834 8.486l1.76-5.978h2.973v-2.02z"
                        />
                     </g>
                  </svg>
                  {projectTitle}
               </h2>
               <h1 className=" text-2xl font-semibold mb-3">
                  {projectObjective}
               </h1>
               <p className="leading-relaxed text-gray-400 mb-3">
                  {projectDescription}
               </p>
               <button
                  className="shadow bg-green-500 hover:bg-green-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  onClick={handleOpen}>
                  {translate("TR_220")}
               </button>
            </div>
         </div>
         <ProjectModal
            isOpen={isModalOpen}
            onClose={closeModal}
            modalDescription={projectDescription}
            modalTitle={projectTitle}
         />
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description">
            <Box sx={{...style, width: 600, padding: 5}}>
               <IconButton
                  color="black"
                  onClick={handleClose}
                  aria-label="close"
                  style={{position: "absolute", top: 0, right: 0}}>
                  <CloseIcon />
               </IconButton>

               <Typography variant="h4" id="parent-modal-title" gutterBottom>
                  Paiement
               </Typography>
               <form onSubmit={handleSubmit}>
                  <TextField
                     label="Montant"
                     variant="outlined"
                     fullWidth
                     type="number"
                     value={price}
                     name="price"
                     onChange={e => {
                        handleDonationAmout(Number(e.target.value));
                     }}
                     margin="normal"
                  />
                  <Select
                     label="Devise"
                     variant="outlined"
                     fullWidth
                     value={currency}
                     name="currency"
                     onChange={e => {
                        setCurrency(e.target.value);
                     }}
                     style={{marginTop: "20px"}}>
                     {/* Options de sélection de la devise */}
                     <MenuItem value="EUR">EUR</MenuItem>
                     <MenuItem value="USD">USD</MenuItem>
                     {/* Ajoutez autant d'options que nécessaire */}
                  </Select>
                  <Button
                     disabled={loading}
                     type="submit"
                     color="primary"
                     style={{marginTop: "16px"}}>
                     Valider
                  </Button>
               </form>
            </Box>
         </Modal>
      </div>
   );
}

export default ProjectComponent;
