import Headerpanel from "../../component/headerpanel";
import {useEffect, useRef, useState} from "react";
import {Button, Pane, Textarea, TextInputField, toaster} from "evergreen-ui";
import {type ProjetType} from "../../../types/projet.type";
import {useForm} from "react-hook-form";
import {Save} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {type ResponseEntityType} from "../../../types/response-entity.type";
import {ProjetService} from "../../../services/projet.service";
import FileUploaderSingleUpload from "./../../component/input/FileInput";

export const ManageProjet = () => {
   const HeaderTitle = "Gestion projet";
   const {id} = useParams();
   const navigate = useNavigate();
   // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
   // @ts-expect-error
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const fileUploaderRef = useRef<FileUploaderSingleUpload>(null);

   useEffect(() => {
      if (id !== undefined) {
         getProjet(id);
      }
   }, []);

   const [saveingLoading, setSaveInloading] = useState<boolean>(false);

   const [userForm, setForm] = useState<ProjetType>({
      id: "0",
      titre: "",
      cover: "",
      description: "",
      objectif: "",
   });

   const {handleSubmit} = useForm();

   const getProjet = (id: string) => {
      void ProjetService.getProjetId(id).then(
         (res: ResponseEntityType<ProjetType>) => {
            if (res.success && typeof res.data === "object") {
               setForm({...res.data});
            }
         },
      );
   };

   function onSubmit(data: any): any {
      setSaveInloading(true);
      if (id !== undefined) {
         // TODO update
         void ProjetService.updateProjet(userForm, id).then(res => {
            if (res.success) {
               const selectedFile = fileUploaderRef.current?.getSelectedFile();
               // Envoyer le fichier
               if (selectedFile !== null) {
                  void ProjetService.uploadProjetCover(id, selectedFile).then(
                     res => {
                        // Gérer la réponse si nécessaire
                     },
                  );
               }
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , le  projet a été modifié ",
               });
               setTimeout(() => {
                  navigate("/admin/projets");
               }, 2000);
            }
         });
      } else {
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         let projetId = "";
         void ProjetService.postCreateProjet(userForm).then(res => {
            if (res.success) {
               projetId = res.data;
               const selectedFile = fileUploaderRef.current?.getSelectedFile();
               if (selectedFile !== null) {
                  void ProjetService.uploadProjetCover(
                     projetId,
                     selectedFile,
                  ).then(res => {
                     // Gérer la réponse si nécessaire
                  });
               }
               setForm(prevState => ({
                  ...prevState,
                  id: "0",
                  titre: "",
                  cover: "",
                  description: "",
                  objectif: "",
               }));
               toaster.success("OTDE NOTIFICATION", {
                  description: "Félicitation , le  projet a été créé ",
               });
               setTimeout(() => {
                  navigate("/admin/projets");
               }, 2000);
            }
         });
      }

      setSaveInloading(false);
   }

   return (
      <div>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
               <Headerpanel
                  title={HeaderTitle}
                  searchValue={""}
                  setSearchValue={() => {}}
               />
               <Pane padding={16} flex={1} borderRadius={3}>
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <Pane display={"flex"} flexDirection={"column"}>
                        <Pane display={"flex"} flexDirection={"row"}>
                           <Pane
                              display={"flex"}
                              flex={1}
                              marginLeft={5}
                              flexDirection={"column"}
                              justifyContent={"space-evenly"}>
                              <TextInputField
                                 isInvalid={userForm.titre === ""}
                                 value={userForm.titre}
                                 name={"titre"}
                                 onChange={(e: any) => {
                                    setForm(prevState => ({
                                       ...prevState,
                                       titre: e.target.value,
                                    }));
                                 }}
                                 placeholder={"Entrez le titre du projet"}
                                 flex={1}
                                 marginTop={5}
                                 label="Veuillez titre lu projet"
                              />

                              <Textarea
                                 marginTop={5}
                                 id="textarea-2"
                                 placeholder=" Veuillez ajoutez l'objectif  ."
                                 isInvalid={userForm.objectif === ""}
                                 onChange={(e: any) => {
                                    setForm(prevState => ({
                                       ...prevState,
                                       objectif: e.target.value,
                                    }));
                                 }}
                                 value={userForm.objectif}
                              />

                              <Textarea
                                 marginTop={10}
                                 id="textarea-2"
                                 placeholder=" Veuillez saisir  la description du projet  ."
                                 isInvalid={userForm.description === ""}
                                 onChange={(e: any) => {
                                    setForm(prevState => ({
                                       ...prevState,
                                       description: e.target.value,
                                    }));
                                 }}
                                 value={userForm.description}
                              />
                           </Pane>
                        </Pane>
                        <Pane
                           display={"flex"}
                           flex={1}
                           padding={10}
                           flexDirection={"column"}
                           justifyContent={"space-evenly"}>
                           <FileUploaderSingleUpload ref={fileUploaderRef} />
                        </Pane>
                        <Pane marginTop={10}>
                           <Button
                              marginY={8}
                              marginRight={12}
                              iconAfter={Save}
                              isLoading={saveingLoading}
                              intent={"success"}>
                              Valider
                           </Button>
                        </Pane>
                     </Pane>
                  </form>
               </Pane>
            </dl>
         </div>
      </div>
   );
};
