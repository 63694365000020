import type {ContactType} from "../types/contact.type";

const ContactMock: ContactType[] = [
   {
      id: 1,
      adresse: "Koumassi",
      nom: "kouassi",
      message: "test tset tsrt",
      email: "koua@mail.com",
      telephone: "22500000000",
   },
   {
      id: 2,
      adresse: "Yopougon",
      nom: "kouassi",
      message: "test tset tsrt",
      email: "koua@mail.com",
      telephone: "22500000000",
   },
];
export default ContactMock;
