import {type PaiementType} from "../types/paiement.type";

export const DonMockStripe: PaiementType[] = [
   {
      id: "1",
      localDate: Date.now().toString(),
      userData: {
         id: undefined,
         nom: "",
         prenom: "",
         username: "",
         email: "",
         telephone: "",
         password: "",
         adresse: "",
         roles: [""],
      },
      projetData: {
         titre: "Cantine à BOUAKE",
         description: "Construction de Cantine à l 'EPP BOUAKE 2",
         objectif: "Construction de Cantine à l 'EPP BOUAKE 2",
         cover: "",
         id: "1",
      },
      orderData: {
         id: "",
         price: 2,
         currency: "EUR",
         method: "paypal",
         intent: "sale",
      },
      status: "",
   },
];

export const DonMockAll: PaiementType[] = [
   {
      id: "1",
      localDate: Date.now().toString(),
      userData: {
         id: undefined,
         nom: "",
         prenom: "",
         username: "",
         email: "",
         telephone: "",
         password: "",
         adresse: "",
         roles: [""],
      },
      projetData: {
         titre: "Cantine à BOUAKE",
         description: "Construction de Cantine à l 'EPP BOUAKE 2",
         objectif: "Construction de Cantine à l 'EPP BOUAKE 2",
         cover: "",
         id: "1",
      },
      orderData: {
         id: "",
         price: 2,
         currency: "EUR",
         method: "paypal",
         intent: "sale",
      },
      status: "",
   },
];

export const DonMockPaypal: PaiementType[] = [
   {
      id: "1",
      localDate: Date.now().toString(),
      userData: {
         id: undefined,
         nom: "",
         prenom: "",
         username: "",
         email: "",
         telephone: "",
         password: "",
         adresse: "",
         roles: [""],
      },
      projetData: {
         titre: "Cantine à BOUAKE",
         description: "Construction de Cantine à l 'EPP BOUAKE 2",
         objectif: "Construction de Cantine à l 'EPP BOUAKE 2",
         cover: "",
         id: "1",
      },
      orderData: {
         id: "",
         price: 2,
         currency: "EUR",
         method: "paypal",
         intent: "sale",
      },
      status: "",
   },
];
