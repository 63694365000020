import type {DatatableContactType} from "../types/datatable-contact.type";
import {type ContactType} from "../types/contact.type";
import ContactMock from "../mocks/contact.mock";
import type {ResponseEntityType} from "../types/response-entity.type";
import URL_MICRO_SERVICE from "./URL";
import AxionService from "./axion.service";
import {type PagginationQueryRequestType} from "../types/paggination-query-request.type";
import {type DatatableResponseType} from "../types/datatable-response.type";

const URL_MICRO_COMMAND = URL_MICRO_SERVICE.CONTACT_COMMANDE_SERVICE;
const URL_MICRO_QUERY = URL_MICRO_SERVICE.CONTACT_QUERY_SERVICE;

/*
 * @Instance axios
 * */
const axiosService = new AxionService();

export const ContactService = {
   // temporairement ...
   async getContactListe(
      searchText: string,
      pageSize: number,
   ): Promise<DatatableContactType> {
      return await new Promise((resolve: any) => {
         const elementPage = 10;
         const data: ContactType[] = ContactService.paginate(
            ContactMock,
            pageSize,
            elementPage,
         );
         const returnArrayDatatable: DatatableContactType = {
            contactListe: data,
            currentPage: pageSize,
            totalRow: ContactMock.length,
         };
         setTimeout(() => resolve(returnArrayDatatable), 1000);
      });
   },
   async getListeContact(
      pagginationQueryRequestType: PagginationQueryRequestType,
   ): Promise<DatatableContactType> {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get<DatatableResponseType<ContactType>>(
               `pageContacts?pageNo=${pagginationQueryRequestType.pageNo}&pageSize=${pagginationQueryRequestType.pageSize}&sortBy=${pagginationQueryRequestType.sortBy}&sortDir=${pagginationQueryRequestType.sortDir}`,
            )
            .then(res => {
               const returnArrayDatatable: DatatableContactType = {
                  contactListe: res.data.content,
                  currentPage: res.data.pageNo,
                  totalRow: res.data.totalElements,
               };
               resolve(returnArrayDatatable);
            })
            .catch(error => reject(error));
      });
   },
   async postContact(
      contact: ContactType,
   ): Promise<ResponseEntityType<string>> {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("create", contact)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
   paginate(
      arrayPaginate: ContactType[],
      numeroPage: number,
      elementsParPage: number,
   ): ContactType[] {
      const indiceDebut = (numeroPage - 1) * elementsParPage;
      const indiceFin = indiceDebut + elementsParPage;
      const elementsDeLaPage = arrayPaginate.slice(indiceDebut, indiceFin);
      return elementsDeLaPage;
   },

   addContact: async (contact: ContactType) => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post("create", contact)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   getContactById: async (id: string) => {
      axiosService.setBaseUrlCom(URL_MICRO_QUERY);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .get(`${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   updateContact: async (contact: ContactType, id: string) => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .post(`update/${id}`, contact)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },

   deleteContact: async (id: string): Promise<ResponseEntityType<string>> => {
      axiosService.setBaseUrlCom(URL_MICRO_COMMAND);
      return await new Promise((resolve: any, reject: any) => {
         axiosService
            .delete(`${id}`)
            .then(res => resolve(res))
            .catch(error => reject(error));
      });
   },
};
