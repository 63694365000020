import {translate} from "../i18n/translate";

interface InputProps {
   inputValue: any;
   setInputValue: any;
   Icon: any;
   type: any;
   code: any;
   id: any;
   obligatory: boolean;
   maxLength: number;
}

function InputIcon({
   inputValue,
   setInputValue,
   Icon,
   type,
   code,
   id,
   obligatory,
   maxLength,
}: InputProps) {
   return (
      <div className="mb-4 flex items-center border-gray-500/50 max-w-sm mx-auto bg-gray-500/50 leading-tight shadow appearance-none rounded-full focus:outline-none focus:shadow-outline">
         <Icon className="h-5 w-5 ml-4 text-white" />
         <input
            maxLength={maxLength}
            value={inputValue}
            onChange={e => {
               setInputValue(e.target.value);
            }}
            className="w-full py-2 px-4 appearance-none placeholder:text-white text-white caret-white bg-transparent focus:outline-none focus:shadow-outline"
            id={id}
            type={type}
            placeholder={
               obligatory ? translate(code).concat("*") : translate(code)
            }
         />
      </div>
   );
}

export default InputIcon;
