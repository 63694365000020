interface BannerProps {
   url: string;
   title: string;
   subTitle: string;
}

export function Banner({url, title, subTitle}: BannerProps) {
   return (
      <div
         className="text-center"
         style={{
            backgroundImage: `url(${url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "100%",
            width: "100%",
            height: "20rem",
            zIndex: " - 1",
         }}>
         <div className="text-center py-20 ">
            <h1 className=" text-5xl md:text-6xl pt-7 text-white font-bold mb-1">
               {" "}
               {title}
            </h1>
            <h5 className="text-2xl  text-white mb-1"> {subTitle}.</h5>
         </div>
      </div>
   );
}
