import {TableSkeleton} from "../skeletons/TableSkeleton";
import type React from "react";

interface ContainerODEprops {
   loading: boolean;
   children: any;
   line: number;
}

const ContainerOTDE: React.FC<ContainerODEprops> = ({
   loading,
   children,
   line,
}) => {
   return <>{!loading ? children : <TableSkeleton lines={line} />}</>;
};
export default ContainerOTDE;
