function InformationContact({title, details1, details2, details3, Icon}: any) {
   return (
      <div>
         <div className="inline-flex">
            <h1 className="text-2xl inline-flex justify-center gap-x-5 align-center">
               <Icon className="-mr-1 h-9 w-9 text-green-500" /> {title}
            </h1>
         </div>
         <div className="px-20 opacity-50">
            <p>{details1}</p>
            <p>{details2}</p>
            <p>{details3}</p>
         </div>
      </div>
   );
}

export default InformationContact;
