import {type DonDtoType} from "../types/don-dto.type";

export const DonListeDto: DonDtoType[] = [
   {
      id: "1",
      nomUser: "Konan Mathieu",
      titreProjet: "Cantine à BOUAKE",
      price: 2.0,
      method: "paypal",
      currency: "EUR",
      status: "VALIDER",
      localDate: Date.now().toString(),
   },
];
