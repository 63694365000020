import {Dropdown} from "./Dropdown";
import {translate} from "../i18n/translate";
import {Link} from "react-router-dom";

function AuthDropdown() {
   return (
      <Dropdown title={translate("TR_6")?.toUpperCase()}>
         <Link
            to={"/connexion"}
            className="text-gray-700 block px-4 py-2 text-sm"
            role="menuitem"
            tabIndex={-1}>
            {translate("TR_7")}
         </Link>
      </Dropdown>
   );
}

export default AuthDropdown;
