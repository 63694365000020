import {FolderOpen} from "@mui/icons-material";
import {ChevronRightIcon, Icon, Pane} from "evergreen-ui";
import {useEffect, useState} from "react";
import {ProjetService} from "./../../../services/projet.service";
import ContainerOTDE from "../container/ContainerOTDE";
import {ProjetStatus, type ProjetType} from "../../../types/projet.type";

const NoProjets: React.FC<any> = () => {
   const [projets, setProjets] = useState<ProjetType[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   useEffect(() => {
      setProjets([]);
      getProjetsData();
   }, []);

   const getProjetsData = () => {
      setLoading(true);
      ProjetService.getProjetDashboadList()
         .then(data => {
            setProjets(data);
            setLoading(false);
         })
         .catch(() => {})
         .finally(() => {
            setLoading(false);
         });
   };
   return (
      <>
         <ContainerOTDE line={3} loading={loading}>
            <Pane
               flexDirection="column"
               justifyContent="flex-start"
               display="flex"
               flex={1}>
               <Pane display="flex" flexDirection="column" alignItems="stretch">
                  {projets.map((projet, index) => (
                     <Pane
                        elevation={4}
                        backgroundColor={
                           projet.isActive === ProjetStatus.ACTIVETED
                              ? "#f1ffef"
                              : "white"
                        }
                        key={index}
                        height={75}
                        padding={5}
                        margin={5}
                        border={true}
                        borderColor={
                           projet.isActive === ProjetStatus.ACTIVETED
                              ? "green"
                              : "gray"
                        }
                        borderWidth={2}
                        borderRadius={10}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between">
                        <Icon
                           icon={FolderOpen}
                           size={25}
                           color={
                              projet.isActive === ProjetStatus.ACTIVETED
                                 ? "#B0F0ABFF"
                                 : "#000"
                           }
                           marginLeft={15}
                        />
                        <Pane
                           display="flex"
                           flexDirection="column"
                           flex={3}
                           marginLeft={15}
                           textAlign="left">
                           <h6>{projet.titre}</h6>
                           <b>{projet.description}</b>
                        </Pane>
                        <Icon
                           icon={ChevronRightIcon}
                           size={16}
                           color="gray"
                           marginRight={15}
                        />
                     </Pane>
                  ))}
               </Pane>
            </Pane>
         </ContainerOTDE>
      </>
   );
};

export default NoProjets;
