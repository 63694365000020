import {Heading, Pane, Paragraph} from "evergreen-ui";
import {useState} from "react";
import {TabDons} from "./component/tabs/dons/index";
import NoProjets from "./component/projets/NoProjets";
import StatistiqueDon from "./component/stats/StatistiqueDon";
import DatepickerOT from "./component/datepicker";
import {StatsCashDonLast} from "./component/stats/StatsCashDonLast";
import Headerpanel from "./component/headerpanel";

export const AdminIndex = () => {
   const [searchValue, setSearchValue] = useState("");
   const title = "Tableau de bord";

   const [valueDate, setDate] = useState({
      startDate: new Date(),
      endDate: new Date().setMonth(11),
   });

   const handleValueChange = (valueDate: any) => {
      setDate(valueDate);
   };

   return (
      <>
         <div className="px-4 sm:px-0"></div>
         <div className="mt-6 border-t border-gray-100">
            <div className="divide-y divide-gray-100">
               <Headerpanel
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  title={title}
                  showSeachValue={true}
               />
               <Pane
                  display="flex"
                  flexDirection={"row"}
                  padding={16}
                  flex={1}
                  borderRadius={3}>
                  <Pane
                     verticalAlign={"top"}
                     flexDirection={"column"}
                     display="flex"
                     flex={2}
                     padding={10}>
                     <Pane
                        flexDirection={"row"}
                        alignItems="center"
                        display="flex"
                        flex={1}>
                        <StatsCashDonLast />
                     </Pane>
                     <Pane
                        flexDirection={"column"}
                        display="flex"
                        flex={1}
                        alignItems="inherit"
                        marginTop={20}
                        paddingTop={50}>
                        <Pane display={"flex"} flexDirection={"row"}>
                           <Heading
                              size={800}
                              color={"#000"}
                              flex={1}
                              marginBottom={5}>
                              TOUT LES PROJETS
                           </Heading>

                           <Paragraph flex={1} alignItems="flex-end">
                              <DatepickerOT
                                 value={valueDate}
                                 callBack={handleValueChange}
                              />
                           </Paragraph>
                        </Pane>
                        <Pane display={"flex"} flexDirection={"row"} flex={1}>
                           <Pane flex={1}>
                              <NoProjets />
                           </Pane>
                           <Pane flex={1}>
                              <StatistiqueDon dateChoosed={valueDate} />
                           </Pane>
                        </Pane>
                     </Pane>
                  </Pane>
                  <Pane
                     verticalAlign={"top"}
                     alignItems="center"
                     display="flex"
                     flex={1}
                     padding={10}>
                     <TabDons />
                  </Pane>
               </Pane>
            </div>
         </div>
      </>
   );
};
